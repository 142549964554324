import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  products: null,
  families: null,
  loading: true,
  filteringCount: 0,
  activeFilters: {},
  product: {},
  searchString: "",
  searching: false,
  filterReset: null,
  filters: {
    family: "all-products",
    product: null,
  },
};

const fetchProductsStart = (state) => {
  return updateObject(state, {
    // loading: true,
  });
};

const fetchProductSuccess = (state, action) => {
  state.product[action.data.products[0].nid] = action.data.products[0];

  return updateObject(state, {
    product: state.product,
    loading: false,
    error: false,
  });
};

const fetchProductsSuccess = (state, action) => {
  //***************** When the Comp first loads it looks for the name products and implements and opens the tab ************////////////
  action.data.filters.forEach((filter) => {
    if (
      filter.label != undefined &&
      filter.name.toLowerCase() == "products_and_implements"
      // filter.label.toLowerCase() == "products and implements"
    ) {
      filter.showChildren = true;
    } else {
      filter.showChildren = false;
    }
    filter.options.forEach((option) => {
      if (option.label == action.familyPath) {
        option.checked = true;
      }
    });
  });

  //******************** Filters meta based on flags  **************//
  var tempMeta = action.data.meta.filter((product) => {
    //Set all the flags to true to start with
    let flag = true;
    //this loops over all the filters in the data.filter //products and Implements, drill characteristics, horespower ext... headers in the tabs
    action.data.filters.forEach((filter) => {
      //THis loops over the options nested inside the data.filters // so options for product and implements ext
      filter.options.forEach((option) => {
        let productProperties =
          product.categorization[filter.name] != null
            ? product.categorization[filter.name].values.map(function (data) {
                return data.tid;
              })
            : []; //This is the name in the filter object tid
        if (option.checked) {
          if (!productProperties.includes(option.tid)) {
            //if the option is check and the the meta api filter dose not have that option tid return flase to the filter
            flag = false;
          }
        }
      });
    });
    return flag;
  });

  action.data.filters.forEach((filter) => {
    filter.options.forEach((option) => {
      option.count = 0;

      //Set the count to 0 (we show how many products are in the count ( Planters(17) )
      tempMeta.forEach((product) => {
        var productFilter =
          product.categorization[filter.name] != null &&
          product.categorization[filter.name].values != null
            ? product.categorization[filter.name].values.map(function (data) {
                return data.tid;
              })
            : [""];

        if (productFilter.includes(option.tid)) {
          option.count++;
        }
      });
    });
  });

  return updateObject(state, {
    ...action.data,
    filterReset: action.data.filters,
    loading: false,
    error: false,
  });
};

const fetchProductsFail = (state) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const fetchProductsSkipped = (state) => {
  return updateObject(state, {
    loading: false,
    error: false,
  });
};

const resetFlags = (state) => {
  state.filters.forEach((filter) => {
    filter.showChildren = true;
    filter.options.forEach((option) => {
      option.checked = false;
      option.count = 0;

      state.meta.forEach((product) => {
        var productFilter =
          product.categorization[filter.name] != null &&
          product.categorization[filter.name].values != null
            ? product.categorization[filter.name].values.map(function (data) {
                return data.tid;
              })
            : [""];

        if (productFilter.includes(option.tid)) {
          option.count++;
        }
      });
    });
  });

  return updateObject(state, {
    filtering: 0,
    filteringCount: 0,
    searchString: null,
    activeProducts: state.products,
    filters: state.filters,
  });
};

const toggleShowChildren = (state, action) => {
  let tempFilters = state.filters;
  tempFilters.forEach((filter) => {
    // console.log(filter.label, filter.name, action.section, filter, action);
    if (filter.label == action.section) {
      let count = 0;
      filter.options.forEach((option) => {
        if (option.checked) {
          count++;
        }
      });
      if (!count) {
        filter.showChildren = !filter.showChildren;
      }
    }
  });
  return updateObject(state, {
    filters: tempFilters,
  });
};

//fires off on checkbox click not family block click
const filterChange = (state, action) => {
  state.filters.forEach((category, index) => {
    if (category.name == action.parent) {
      category.options.forEach((option, i) => {
        if (option.name == action.child) {
          state.filters[index].options[i].checked = !state.filters[index]
            .options[i].checked;
          state.filteringCount += state.filters[index].options[i].checked
            ? 1
            : -1;

          //So if the name of the action.child is the same as option.name make the checkbox true
          // state.filters[category].options[option] = !state.filters[category].options[option];
        }
      });
    }
  });

  var tempProducts = state.meta.filter((product) => {
    let flag = true;

    state.filters.forEach((filter) => {
      filter.options.forEach((option) => {
        var productFilter =
          product.categorization[filter.name] != null &&
          product.categorization[filter.name].values != null
            ? product.categorization[filter.name].values.map(function (data) {
                return data.tid;
              })
            : [""];

        if (option.checked) {
          if (!productFilter.includes(option.tid)) {
            flag = false;
          }
        }
      });
    });
    return flag;
  });

  state.filters.forEach((filter) => {
    //   console.log(filter);
    filter.options != undefined &&
      filter.options.forEach((option) => {
        // console.log(option.count);
        option.count = 0;
        //console.log(option.count);
        // option.count = 0;
        tempProducts.forEach((product) => {
          if (product.categorization[filter.name] != null) {
            // console.log(product.categorization[filter.name].values);
            product.categorization[filter.name].values.map(function (
              data,
              index
            ) {
              // console.log(option);
              if (data.tid == option.tid) {
                // console.log(data);
                // console.log(option);
                option.count++;
              }
            });
          }
        });
      });
  });

  return updateObject(state, {
    filtering: state.filteringCount,
    activeProducts: tempProducts,
    filters: state.filters,
  });
};

const searchStringUpdate = (state, action) => {
  for (const filter in state.filters) {
    state.filters[filter].options.forEach((current) => {
      current.state = false;
    });
  }
  state.filteringCount = 0;
  return updateObject(state, {
    searchString: action.string.trim(),
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCTS_START:
      return fetchProductsStart(state, action);
    case actionTypes.FETCH_PRODUCTS_SUCCESS:
      return fetchProductsSuccess(state, action);
    case actionTypes.FETCH_PRODUCT_SUCCESS:
      return fetchProductSuccess(state, action);
    case actionTypes.FETCH_PRODUCTS_FAIL:
      return fetchProductsFail(state, action);
    case actionTypes.FETCH_PRODUCTS_SKIPPED:
      return fetchProductsSkipped(state, action);
    case actionTypes.FILTER_CHANGE:
      return filterChange(state, action);
    case actionTypes.SEARCH_STRING_UPDATE:
      return searchStringUpdate(state, action);
    case actionTypes.TOGGLE_SHOW_CHILDREN:
      return toggleShowChildren(state, action);
    case actionTypes.RESET_FLAGS:
      return resetFlags(state, action);

    default:
      return state;
  }
};

export default reducer;
