import React, { Fragment } from "react";
import Link from "react-router-dom/Link";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Image,
  Slide,
  Slider,
} from "pure-react-carousel";
import classes from "./ProductCarousel.module.scss";
import "pure-react-carousel/dist/react-carousel.es.css";
import ConditionalWrapper from "../../hoc/ConditionalWrapper/ConditionalWrapper";

export default class ProductCarousel extends React.PureComponent {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    setTimeout(() => {}, 400);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout();
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render(props) {
    let slides = this.props.products
      .sort((a, b) => a.field_weight_value - b.field_weight_value)
      .map((current, index) => {
        let link;
        switch (this.props.origin.language) {
          case "en":
            link = current.product_link_us;
            break;
          case "ru":
            link = current.product_link_russia;
            break;
          case "uk":
            link = current.product_link_ukraine;
            break;
          case "ro":
            link = current.product_link_intl;
            break;
          case "es":
            link = current.product_link_intl;
            break;
          default:
            link = current.product_link_intl;
        }

        let temp = current.family_name
          .toString()
          .toLowerCase()
          .replace(" ", "-");

        return temp != "precision-application" &&
          temp != "new-products" &&
          current.product_link_us != "implements/united-states/new-products" ? (
          <Slide
            key={current.nid}
            index={index}
            aria-label={current.family_name}
            role="presentation"
          >
            <ConditionalWrapper
              condition={this.props.auth}
              wrapper={(children) => (
                <div className={"HoverOver"}>
                  <a
                    className={"EditThisLink"}
                    target="_blank"
                    href={`https://cms.greatplainsag.com/${this.props.origin.language}/node/${current.nid}/edit`}
                  >
                    Edit This Content
                  </a>
                  {children}
                </div>
              )}
            >
              <Link
                className={"Link"}
                to={`/${this.props.origin.language}/${link}`}
                aria-label={current.family_name}
                role="presentation"
              >
                <div
                  className={`${classes.Slide} Slide`}
                  aria-label={current.family_name}
                  role="presentation"
                >
                  <div
                    className={`${classes.Slide2} Slide2`}
                    role="presentation"
                  >
                    <div
                      className={`${classes.Slide3} Slide3`}
                      role="presentation"
                    >
                      {/* <Image src={process.env.REACT_APP_IMAGE_URL + current.field_product_studio_image} /> */}
                      {this.state.width > 768 ? (
                        <Image
                          role="img"
                          src={current.field_img_crsl_asset_url}
                          className={`${classes.Image} Image`}
                          aria-label={current.family_name}
                        />
                      ) : (
                        <Image
                          role="img"
                          src={current.field_img_crsl_mobl_asset_url}
                          className={`${classes.Image} Image`}
                          aria-label={current.family_name}
                        />
                      )}
                    </div>
                    <p className={`${classes.FamilyName} FamilyName`}>
                      {current.family_name}
                    </p>
                  </div>
                </div>
              </Link>{" "}
            </ConditionalWrapper>
          </Slide>
        ) : null;
      });

    let slideCount = this.props.products.length;

    let visibleSlides = 3;
    if (this.state.width >= 400) visibleSlides = 4;
    if (this.state.width >= 768) visibleSlides = 5;
    if (this.state.width >= 1000) visibleSlides = 5;
    if (this.state.width >= 1450) visibleSlides = 5;
    if (this.state.width >= 1700) visibleSlides = 6;

    let naturalSlideHeight = 360;
    if (this.state.width <= 400) naturalSlideHeight = 600;
    if (this.state.width <= 564) naturalSlideHeight = 600;
    if (this.state.width <= 768) naturalSlideHeight = 500;
    if (this.state.width >= 769) naturalSlideHeight = 380;

    let step = visibleSlides - 1 ? visibleSlides - 1 : visibleSlides;

    return (
      <Fragment>
        {this._isMounted ? (
          <CarouselProvider
            aria-label="Products"
            role="marquee"
            className={classes.Carousel}
            visibleSlides={visibleSlides}
            totalSlides={slideCount}
            step={step}
            naturalSlideWidth={400}
            naturalSlideHeight={naturalSlideHeight}
            hasMasterSpinner
            infinite="true"
            touchEnabled={true}
            lockOnWindowScroll={true}
          >
            <Slider role="marquee">{slides}</Slider>
            <div className={`${classes.ButtonDiv} ButtonDiv`}>
              <ButtonBack
                className={`${classes.BackButton} BackButton`}
                role="button"
              >
                &#10094; Previous
              </ButtonBack>

              <ButtonNext
                className={`${classes.NextButton} NextButton`}
                role="button"
              >
                Next &#10095;
              </ButtonNext>
            </div>
            {/*<ButtonBack className={classes.BackButton2}>*/}
            {/*  &#10094; Previous*/}
            {/*</ButtonBack>*/}
            {/*<ButtonNext className={classes.NextButton2}>*/}
            {/*  Next &#10095;*/}
            {/*</ButtonNext>*/}
          </CarouselProvider>
        ) : null}
      </Fragment>
    );
  }
}
