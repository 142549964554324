export default function shading() {
  return (
    {
      Darkest: 'rgba(0, 0, 0, .3)',
      Darker: 'rgba(0, 0, 0, .2)',
      Dark: 'rgba(0, 0, 0, .1)',
      Transparent: 'rgba(255, 255, 255, .0)',
      Light: 'rgba(255, 255, 255, .1)',
      Lighter: 'rgba(255, 255, 255, .2)',
      Lightest: 'rgba(255, 255, 255, .3)'
    }
  )
}
