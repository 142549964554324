import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  dealers: null,
  loading: true,
};

const fetchDealerIntStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchDealerIntSuccess = (state, action) => {
  return updateObject(state, {
    dealers: action.data,
    loading: false,
    error: false,
  });
};

const fetchDealerIntFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DEALERINT_START:
      return fetchDealerIntStart(state, action);
    case actionTypes.FETCH_DEALERINT_SUCCESS:
      return fetchDealerIntSuccess(state, action);
    case actionTypes.FETCH_DEALERINT_FAIL:
      return fetchDealerIntFail(state, action);

    default:
      return state;
  }
};

export default reducer;
