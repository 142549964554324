import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  divisions: null,
  loading: true,
  error: false,
};

const fetchDivisionsStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchDivisionsSuccess = (state, action) => {
  let temp = updateObject(state, {
    banner: action.data.banners,
    divisions: action.data.divisions,
    loading: false,
    error: false,
  });
  return temp;
};

const fetchDivisionsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DIVISIONS_START:
      return fetchDivisionsStart(state, action);
    case actionTypes.FETCH_DIVISIONS_SUCCESS:
      return fetchDivisionsSuccess(state, action);
    case actionTypes.FETCH_DIVISIONS_FAIL:
      return fetchDivisionsFail(state, action);

    default:
      return state;
  }
};

export default reducer;
