import classes from "../Footlight.module.scss";
import React from "react";
import { uuidv4 } from "../../../shared/utility";
let key = uuidv4();

export default function Shop() {
  return (
    <>
      <div className={`${classes.FootlightChildWrapper} FootlightChildWrapper`}>
        <div className={`${classes.InnerWrapper} InnerWrapper`}>
          <h3 className={`${classes.Header} Header`}>
            <a href="https://shopgreatplains.com/">BUY APPAREL</a>
          </h3>
          <div className={`${classes.Content} Content`}>
            <div className={`${classes.ImageWrapper} ImageWrapper`}>
              <picture>
                {/*Use AVIF iamge if the browser supports it*/}
                <source
                  srcSet={
                    "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/Apparel.avif"
                  }
                  type="image/avif"
                />
                {/*Use webp iamge if the browser supports it*/}
                <source
                  srcSet={
                    "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/Apparel.webp"
                  }
                  type="image/avif"
                />
                {/*Fall back if the browser does not support avig*/}
                <img
                  className={`${classes.Image} Image`}
                  src="https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/Apparel.jpg"
                  alt={"Great plains Hat"}
                  loading={"lazy"}
                />
              </picture>
            </div>
            <div>
              Take us more places than just the field. Find apparel, hats, cups,
              bags, bar stools, and more in our apparel store.
            </div>
          </div>
          <div className={`${classes.Buttons} Buttons`}>
            <a href="https://shopgreatplains.com/">
              {" "}
              <button className={`${classes.Button} Button`}>
                {" "}
                Shop Apparel
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
