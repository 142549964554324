import * as actionTypes from "./../actions/actionTypes";
import { updateObject } from "../../shared/utility";
import Fuse from "fuse.js";

const initialState = {
  searchList: {},
  loading: true,
  searchString: "",
  searching: false,
};

const fetchSearchStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchSearchSuccess = (state, action) => {
  let searchList = [];
  action.data.forEach((v, k) => {
    searchList.push(v);
  });

  return updateObject(state, {
    searchList: searchList,
    loading: false,
    error: false,
  });
};

const fetchSearchFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const searchStringUpdate = (state, action) => {
  return updateObject(state, {
    searchString: action.string,
  });
};

const searchStringSubmit = (state, action) => {
  for (const filter in state.filters) {
    state.filters[filter].options.forEach((current) => {
      current.state = false;
    });
  }
  state.filteringCount = 0;

  const options = {
    includeScore: true,
    // equivalent to `
    // keys: [['title']]
    // keys: ["title.field_description_callout.teaser.drill_characteristics.fertilizer_applicator_characteristics.fertilizer_dry_capacity.fertilizer_wet_capacity.horsepower.field_model_numbers.planter_characteristics.products_and_implements.rotary_cutter_characteristics.row_spacing.tillage_characteristics.transport_height.transport_width.working_tillage_width.field_ag_solutions"],

    // ],/
    keys: [
      "title",
      "field_description_callout",
      "teaser.drill_characteristics",
      "fertilizer_applicator_characteristics",
      "fertilizer_dry_capacity",
      "fertilizer_wet_capacity",
      "horsepower",
      "field_model_numbers",
      "planter_characteristics",
      "products_and_implements",
      "rotary_cutter_characteristics",
      "row_spacing",
      "tillage_characteristics",
      "transport_height",
      "transport_width",
      "working_tillage_width",
    ],
    // keys: ["title", ],
  };

  const fuse = new Fuse(state.products, options);

  const tempSearchedProducts = fuse.search(state.searchString);

  return updateObject(state, {
    searchedProducts: tempSearchedProducts,
  });
};

const searchStringReset = (state, action) => {
  return updateObject(state, {
    searchedProducts: [],
    searchString: "",
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SEARCH_START:
      return fetchSearchStart(state, action);
    case actionTypes.FETCH_SEARCH_SUCCESS:
      return fetchSearchSuccess(state, action);
    case actionTypes.FETCH_SEARCH_FAIL:
      return fetchSearchFail(state, action);

    // case actionTypes.ALL_SEARCH_STRING_UPDATE: return searchStringUpdate(state, action);
    // case actionTypes.ALL_SEARCH_STRING_SUBMIT: return searchStringSubmit(state, action);
    // case actionTypes.ALL_SEARCH_STRING_RESET: return searchStringReset(state, action);

    default:
      return state;
  }
};

export default reducer;
