import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  country: "International",
  language: "en-gb", //We set a starter lang but it changes and re renders the page after the origin call
  loading: true,
  searchString: "",
};

const fetchOriginStart = (state) => {
  return updateObject(state, { loading: true });
};

const fetchOriginSuccess = (state, action) => {
  // console.log(action.data)
  return updateObject(state, {
    // country: data.location.country,
    ...action.data,
    loading: false,
    error: false,
  });
};

const fetchOriginFail = (state) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};
//ORIGIN UPDATE will update the sites language preferences en => ru. Thats all it dose
const fetchOriginUpdate = (state, action) => {
  // state = initialState;
  return updateObject(state, {
    // ...action.data,
    language: action.language,
    loading: false,
    error: false,
    //   language: action.language,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORIGIN_START:
      return fetchOriginStart(state, action);
    case actionTypes.FETCH_ORIGIN_SUCCESS:
      return fetchOriginSuccess(state, action);
    case actionTypes.FETCH_ORIGIN_FAIL:
      return fetchOriginFail(state, action);
    case actionTypes.FETCH_ORIGIN_UPDATE:
      return fetchOriginUpdate(state, action);

    default:
      return state;
  }
};

export default reducer;
