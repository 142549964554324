import React, { Component } from "react";
import classes from "./Footlight.module.scss";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import DealerSearchInt from "./Blocks/DealerSearchInt";
import Shop from "./Blocks/Shop";
import DealerSearch from "./Blocks/DealerSearch";
import CustomerStories from "./Blocks/CustomerStories";
import Shows from "./Blocks/Shows";
import Company from "./Blocks/Company";

class FootLight extends Component {
  constructor(props) {
    super(props);
    this.state = { isUSA: true };
    this._isMounted = false;
    this.BlockPicker = this.BlockPicker.bind(this);
  }

  componentDidMount() {
    this.props.onFetchDealerInt(this.props.origin.language);
    this.props.onFetchUpcomingShows(this.props.origin.language);
    this._isMounted = true;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    //call your api and update state with new props
    if (this.props.location == nextProps.location || this.state.mount == true) {
      return true;
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  BlockPicker(type, props, props2) {
    switch (type) {
      case "dealer-search-int":
        return <DealerSearchInt lang={props} dealer={props2} />;
        break;
      case "dealer-search":
        // this.setState({ count: this.state.count + 1 });
        return <DealerSearch />;
        break;
      case "shop":
        // this.setState({ count: this.state.count + 1 });
        return <Shop />;
        break;
      case "customer-stories":
        // this.setState({ count: this.state.count + 1 });
        return <CustomerStories lang={props} />;
        break;
      case "shows":
        // this.setState({ count: this.state.count + 1 });
        return (
          <Shows
            shows={props}
            lang={props2}
            country={props2 != "en" ? "International" : "U.S. Domestic"}
          />
        );
        break;
      case "company":
        // this.setState({ count: this.state.count + 1 });
        return <Company lang={props} />;
        break;
      default:
        null;
    }
  }

  render() {
    const today = new Date();
    return (
      <React.Fragment>
        {this._isMounted && !this.props.origin.loading ? (
          <div className={`${classes.Container} Container`}>
            {/*<div className={classes.BlockWrapper}>*/}
            {this.props.origin.language == "en"
              ? this.BlockPicker("shop")
              : null}
            {/*{this.BlockPicker("dealer-search")}*/}
            {this.props.origin.language != "en"
              ? this.BlockPicker("company", this.props.origin.language)
              : null}
            {location.pathname == "/en/dealer-search"
              ? this.BlockPicker("customer-stories", this.props.origin.language)
              : null}

            {location.pathname != "/en/dealer-search" &&
            this.props.origin.language === "en"
              ? this.BlockPicker("dealer-search")
              : null}

            {this.props.origin.language != "en" &&
            // this.props.origin.language != "es" &&
            // this.props.origin.language != "ro" &&
            !location.pathname.includes("international-dealers")
              ? this.BlockPicker(
                  "dealer-search-int",
                  this.props.origin.language,
                  this.props.dealers
                )
              : null}
            <div
              className={`${classes.FootlightChildWrapper} FootlightChildWrapper`}
            >
              {this.props.upcomingShows != undefined &&
              this.props.upcomingShows.upcomingShows != undefined
                ? this.BlockPicker(
                    "shows",
                    this.props.upcomingShows,
                    this.props.origin.language
                  )
                : null}
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    origin: {
      ...state.origin,
    },
    dealers: { ...state.dealerInt },
    social: state.nav.nav.social,
    upcomingShows: state.upcomingShows,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onFetchDealerInt: (en) => dispatch(actions.fetchDealerInt(en)),
    onFetchUpcomingShows: (Lang) => dispatch(actions.fetchUpcomingShows(Lang)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FootLight);
