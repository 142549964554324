import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  finance: null,
  loading: true,
};

const fetchFinanceStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchFinanceSuccess = (state, action) => {
  return updateObject(state, {
    finance: action.data,
    loading: false,
    error: false,
  });
};

const fetchFinanceFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FINANCE_START:
      return fetchFinanceStart(state, action);
    case actionTypes.FETCH_FINANCE_SUCCESS:
      return fetchFinanceSuccess(state, action);
      state, action;
    case actionTypes.FETCH_FINANCE_FAIL:
      return fetchFinanceFail(state, action);
    default:
      return state;
  }
};

export default reducer;
