import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Panel from "./Panel/Panel";

export default function Banner(props) {
  const [isMounted, setIsMounted] = useState(false);
  const mediaMatch = window.matchMedia("(max-width: 768px)");
  const [matches, setMatches] = useState(mediaMatch.matches);
  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    const mediaListener = window.matchMedia("(max-width: 1200px)");
    mediaListener.addEventListener("change", handler);
    return () => mediaListener.removeEventListener("change", handler);
  });

  let multipleSlides = props.banners.length == 1 ? false : true;

  function Start(data) {
    let date = new Date();
    if (
      data.dates != undefined &&
      data.dates.start_date != undefined &&
      data.dates.start_date.start_date != undefined &&
      data.dates.start_date.start_date.length > 1
    ) {
      let date2 =
        data.dates != undefined &&
        data.dates.start_date != undefined &&
        data.dates.start_date.start_date != undefined &&
        data.dates.start_date.start_date.length > 1
          ? new Date(data.dates.start_date.start_date)
          : null;
      if (date2 <= date) {
        return data;
      } else if (date2 > date) {
        return null;
      }
    } else return data;
  }

  function End(data) {
    let date = new Date();
    if (
      data.dates != undefined &&
      data.dates.end_date != undefined &&
      data.dates.end_date.start_date != undefined &&
      data.dates.end_date.start_date.length > 1
    ) {
      let date2 =
        data.dates != undefined &&
        data.dates.end_date != undefined &&
        data.dates.end_date.start_date != undefined &&
        data.dates.end_date.start_date.length > 1
          ? new Date(data.dates.end_date.start_date)
          : null;
      if (date2 > date) {
        // console.log("True", data.expiration_date, Today);
        return data;
      } else if (date2 == date) {
        return null;
      } else if (date2 < date) {
        return null;
      }
    } else return data;
  }

  // //THis is here to check to unmount and mount the Carousel, it causes a memory leak if its mounted and state changes
  if (isMounted) {
    return (
      <Carousel
        showIndicators={multipleSlides}
        swipeScrollTolerance={12}
        showThumbs={props.showThumbs}
        showStatus={props.showStatus}
        infiniteLoop={multipleSlides}
        useKeyboardArrows={props.useKeyboardArrows}
        autoPlay={props.autoPlay}
        stopOnHover={props.stopOnHover}
        swipeable={matches ? false : multipleSlides} //Allows user to swipe the carousel
        interval={props.interval}
        transitionTime={props.transitionTime}
      >
        {props.banners
          .filter(Start)
          .filter(End)
          .sort((a, b) => a.weight - b.weight)
          .map((current) => (
            <Panel
              key={current.nid}
              data={current}
              mobile={current.banner_image.values[0].images["500px_uri"]}
              desktop={current.banner_image.values[0].images["1300px_uri"]}
              lang={props.lang}
              auth={props.auth}
            />
          ))}
      </Carousel>
    );
  } else return <div></div>;
}
