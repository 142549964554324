import React, { useEffect, useState } from "react";
import Parser from "html-react-parser";
import classes from "./Panel.module.scss";
import Link from "react-router-dom/Link";
import LearnMoreButton from "../../LearnMoreButton/LearnMoreButton";
import T from "./../../../hoc/Language/translate.js";
import ConditionalWrapper from "../../../hoc/ConditionalWrapper/ConditionalWrapper";

export default React.memo(function Panel(props) {
  //This sets the state so we can pic images basaed on screen size.
  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    //This function watches for window size change
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
  };

  const desktop = useMediaQuery("(min-width: 1800px)");
  const moblie = useMediaQuery("(max-width: 768px)");
  //End of window size change function

  let imageUrl;
  if (desktop) {
    imageUrl =
      props != undefined &&
      props.desktop != undefined &&
      props.desktop.length != 0
        ? props.desktop
        : props.data.banner_image.values[0].images["1300px_uri"];
  } else if (!desktop && moblie) {
    imageUrl =
      props != undefined && props.mobile != undefined && props.mobile.length > 2
        ? props.mobile
        : props.data.banner_image.values[0].images["500px_uri"];
  } else imageUrl = props.data.banner_image.values[0].images["1300px_uri"];

  const CardAlignment = (position) => {
    switch (position) {
      case "left":
        return classes.HorizLeft;
      case "right":
        return classes.HorizRight;
      case "center":
        return classes.HorizRight;
      default:
        return classes.HorizRight;
    }
  };

  let bgImageStyle;

  bgImageStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundPosition: `${props.data.vertical_alignment.value} ${props.data.horizontal_alignment.value}`,
  };

  let title;

  if (props.data.banner_title != undefined) {
    if (
      props.products != undefined &&
      props.products &&
      props.data.banner_title != undefined &&
      props.data.banner_title.value != undefined
    ) {
      title = <h1>{Parser(`${props.data.banner_title.value}`)}</h1>;
    } else {
      title = <h2>{Parser(`${props.data.banner_title.value}`)}</h2>;
    }
  } else title = null;

  return (
    <ConditionalWrapper
      condition={props.auth}
      wrapper={(children) => (
        <div className={"HoverOver"}>
          <a
            className={"EditThisLink"}
            target="_blank"
            href={`https://cms.greatplainsag.com/${props.lang}/node/${props.data.nid}/edit`}
          >
            Edit This Content
          </a>
          {children}
        </div>
      )}
    >
      <div
        className={`${classes.Wrapper} ${
          classes.StandardWrapper
        } ${CardAlignment(
          props.data.caption_position.value != undefined
            ? props.data.caption_position.value
            : "right"
        )}`}
        style={bgImageStyle}
      >
        {props.data.image_only.value != true && (
          <div className={`${classes.Card} `}>
            {props.newProduct != undefined && props.newProduct != false ? (
              <div className={classes.NewProductBanner}>
                {T("New", props.lang)}
              </div>
            ) : null}
            <h3>{props.data.super_text}</h3>
            {title}

            {/* doing the logic for this inline is hairy at best. these are above */}

            <div className={classes.FieldCaption} style={{ marginTop: "1rem" }}>
              {props.data.field_caption_summary != undefined &&
              props.data.field_caption_summary.length
                ? props.data.field_caption_summary
                : null}
            </div>
            {props.data.caption != undefined &&
            props.data.caption.value != undefined &&
            props.data.caption.value.length ? (
              <p>
                {props.data.caption != undefined &&
                props.data.caption.value != undefined &&
                props.data.caption.value.length > 1
                  ? Parser(`${props.data.caption.value}`)
                  : null}
              </p>
            ) : null}
            {props.data.read_more_link != null &&
            props.data.read_more_link.value != null &&
            props.data.read_more_link.value.length > 1 &&
            !props.data.read_more_link.value.includes("http") ? (
              <div className={classes.ButtonWrapper}>
                {props.data.read_more_link != null &&
                  props.data.read_more_link.value != null && (
                    <div className={classes.ButtonWrapper2}>
                      {props.data.read_more_link.value.length > 1 &&
                      props.data.read_more_link.value.startsWith("/") ? (
                        <Link to={`${props.data.read_more_link.value}`}>
                          <button className={"Button"}>
                            {T("Learn More", props.lang)}
                          </button>
                        </Link>
                      ) : (
                        <a
                          target="_blank"
                          href={`${props.data.read_more_link.value}`}
                          className={"Link"}
                        >
                          <button className={"Button"}>
                            {T("Learn More", props.lang)}
                          </button>
                        </a>
                      )}
                    </div>
                  )}
              </div>
            ) : (
              <div className={classes.ButtonWrapper}>
                {props.data.read_more_link != null &&
                  props.data.read_more_link.value != null && (
                    <LearnMoreButton
                      buttonLink={`${props.data.read_more_link.value}`}
                      lang={props.lang}
                    />
                  )}
              </div>
            )}
          </div>
        )}
      </div>
    </ConditionalWrapper>
  );
});
