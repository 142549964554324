import React from "react";
import { Helmet } from "react-helmet";
import { uuidv4 } from "../../shared/utility";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import Spinner from "../UI/Spinner/Spinner";
import classes from "./locations.module.scss";
import T from "./../../hoc/Language/translate";

class Locations extends React.PureComponent {
  async componentDidMount() {
    let LocationsLang = this.props.origin.language;
    if (LocationsLang === "es" || LocationsLang === "ro") {
      this.props.onFetchLocations("en-gb");
    } else this.props.onFetchLocations(LocationsLang);
  }



  render() {
    let content = <Spinner />;
    const locations =
      this.props.locations != undefined ? this.props.locations.Locations : null;
    const lang = this.props.origin.language;
    if (!this.props.origin.loading && !this.props.locations.loading) {
      if (!this.props.locations.error && !this.props.origin.error) {
        content = (
          <div
            id={`ContentMainWrapper`}
            className={`ContentMainWrapper`}
            key={uuidv4()}
          >
            <div id={`ContentHeader`} className={`ContentHeader`}>
              {/*spans in any headers change the header color to orange. */}
              <h1 className={classes.Title}>
                <span>Great Plains </span>{" "}
                {T("International Offices", this.props.origin.language)}
              </h1>
            </div>
            <div id={`ColumnWrapper`} className={`Wrapper ColumnWrapper`}>
              <div id={`ContentColumn`} className={`ContentColumn`}>
                <div id={`BodyContent`} className={`BodyContent`}>
                  {locations
                    .sort((a, b) => a.weight - b.weight)
                    .map(function (data) {
                      return (
                        <div className={`flashcard`} key={uuidv4()}>
                          <div className={classes.InnerWrap}>
                            <h2 className={classes.Title}>{data.title}</h2>
                            <h4 className={classes.SubTitle}>
                              {T("Address", lang)}
                            </h4>
                            <div>
                              <p> {data.address_1.value} </p>
                              <p>
                                {data.address_2 ? data.address_2.value : null}
                              </p>
                              <p>
                                {data.address_3 ? data.address_3.value : null}{" "}
                              </p>
                              <p>
                                {data.city.value}
                                {", "}
                                {data.state ? data.state.value : null}{" "}
                              </p>
                              <p>
                                {data.postal_code
                                  ? data.postal_code.value
                                  : null}
                              </p>
                              {data.categorization.country && data.categorization.country.values
                                ? data.categorization.country.values[0].name
                                : null}
                            </div>
                            <h4 className={classes.SubTitle}>
                              {T("Phone Number", lang)}
                            </h4>
                            <p>Phone: {data.phone ? data.phone.value : null}</p>
                            {data.email.value ? (
                              <h4 className={classes.SubTitle}>
                                {T("Email", lang)}
                              </h4>
                            ) : null}
                            {data.email.value ? (
                              <p> {data.email.value}</p>
                            ) : null}
                            <h4 className={classes.SubTitle}>
                              {T("Website", lang)}
                            </h4>
                            {data.website ? (
                              <a href={data.website.value}>
                                {data.website.value}
                              </a>
                            ) : null}
                            <div className={classes.Img} key={uuidv4()}>
                              {data.field_location_image ? (
                                <img
                                  src={
                                    data.field_location_image
                                      ? data.field_location_image.values[0]
                                          .images["275_px_uri"]
                                      : null
                                  }
                                  alt={
                                    data.field_location_image
                                      ? data.field_location_image.values[0].alt
                                      : null
                                  }
                                ></img>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
    return (
      <div>
        <Helmet>
          <title>Great Plains International offices | Great Plains Ag</title>
          <meta
            name="description"
            content="Great Plains International offices"
          />
        </Helmet>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    origin: { ...state.origin },
    locations: state.locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchLocations: (lang) => dispatch(actions.fetchLocations(lang)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Locations)
);
