import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  forms: null,
  loading: true,
  searchString: "",
  searching: false,
};

const fetchFormsStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchFormsSuccess = (state, action) => {
  return updateObject(state, {
    forms: action.data,
    loading: false,
    error: false,
  });
};

const fetchFormsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FORMS_START:
      return fetchFormsStart(state, action);
    case actionTypes.FETCH_FORMS_SUCCESS:
      return fetchFormsSuccess(state, action);
      state, action;
    case actionTypes.FETCH_FORMS_FAIL:
      return fetchFormsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
