import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  upcomingShows: null,
  loading: true,
  searchString: "",
  searching: false,
};

const fetchUpcomingShowsStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchUpcomingShowsSuccess = (state, action) => {
  return updateObject(state, {
    upcomingShows: action.data,
    loading: false,
    error: false,
  });
};

const fetchUpcomingShowsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UPCOMING_SHOWS_START:
      return fetchUpcomingShowsStart(state, action);
    case actionTypes.FETCH_UPCOMING_SHOWS_SUCCESS:
      return fetchUpcomingShowsSuccess(state, action);
      state, action;
    case actionTypes.FETCH_UPCOMING_SHOWS_FAIL:
      return fetchUpcomingShowsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
