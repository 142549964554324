import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchUpcomingShows = (lang) => {
  const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];
  let OriginLanguage = langArray.includes(lang) ? lang : "en-gb";
  return (dispatch) => {
    dispatch(fetchUpcomingShowsStart());
    axios
      .get(`/upcomingShows/${OriginLanguage}/`)
      .then((response) => {
        dispatch(fetchUpcomingShowsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchUpcomingShowsFail(err));
      });
  };
};

/***********************************************************
// Fetch All UpcomingShows
***********************************************************/
export const fetchUpcomingShowsStart = () => {
  return {
    type: actionTypes.FETCH_UPCOMING_SHOWS_START,
  };
};

export const fetchUpcomingShowsSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_UPCOMING_SHOWS_SUCCESS,
  };
};

export const fetchUpcomingShowsFail = (error) => {
  return {
    type: actionTypes.FETCH_UPCOMING_SHOWS_FAIL,
    error: error,
  };
};
