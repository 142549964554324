import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  features: null,
  loading: true,
  searchString: "",
  searching: false,
};

const fetchFeaturesStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchFeaturesSuccess = (state, action) => {
  return updateObject(state, {
    features: action.data,
    loading: false,
    error: false,
  });
};

// const fetchFeaturesSuccess = (state, action) => {

//   return updateObject(state, {
//     ...action.data,
//     loading: false,
//     error: false,
//   });

// };

const fetchFeaturesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FEATURES_START:
      return fetchFeaturesStart(state, action);
    case actionTypes.FETCH_FEATURES_SUCCESS:
      return fetchFeaturesSuccess(state, action);
      state, action;
    case actionTypes.FETCH_FEATURES_FAIL:
      return fetchFeaturesFail(state, action);
    // case actionTypes.FILTER_CHANGE: return filterChange(state, action);
    // case actionTypes.SEARCH_STRING_UPDATE: return searchStringUpdate(state, action);
    default:
      return state;
  }
};

export default reducer;
