import React from "react";
import { Slider } from "react-burgers";
import classes from "./DrawerToggle.module.scss";

const drawerToggle = (props) => (
  <div className={classes.DrawerToggle}>
    <Slider onClick={props.clicked} color={"#fff"} active={props.open} />
  </div>
);

export default drawerToggle;
