import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchForms = (lang) => {
  const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];
  let OriginLanguage = langArray.includes(lang) ? lang : "en";

  return (dispatch) => {
    dispatch(fetchFormsStart(OriginLanguage));
    axios
      .get(`/forms/${OriginLanguage}`)
      .then((response) => {
        dispatch(fetchFormsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchFormsFail(err));
      });
  };
};

/***********************************************************
 // Fetch All Forms
 ***********************************************************/
export const fetchFormsStart = () => {
  return {
    type: actionTypes.FETCH_FORMS_START,
  };
};

export const fetchFormsSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_FORMS_SUCCESS,
  };
};

export const fetchFormsFail = (error) => {
  return {
    type: actionTypes.FETCH_FORMS_FAIL,
    error: error,
  };
};
