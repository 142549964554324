import React from "react";
import Link from "react-router-dom/Link";
import classes from "./HomeBanner.module.scss";
import T from "../../../hoc/Language/translate";
import Parser from "html-react-parser";
import ConditionalWrapper from "../../../hoc/ConditionalWrapper/ConditionalWrapper";

export default function HomeBanner(props) {
  return (
    <div className={`${classes.Wrapper} testGlobalClass`}>
      <div className={classes.Card}>
        {/* inherited style */}
        <ConditionalWrapper
          condition={props.auth}
          wrapper={(children) => (
            <div className={"HoverOver"}>
              <a
                className={"EditThisLink"}
                target="_blank"
                href={`https://cms.greatplainsag.com/${props.lang}/node/316/edit`}
              >
                Edit This Content
              </a>
              {children}
            </div>
          )}
        >
          <h1>{props.title}</h1>
          {/*use node 316 for this text*/}
          <p className={classes.OverFlowText}>{Parser(props.content)}</p>
          {/* This span is here because the arrow was floating right as a background on the element because of line breaks.. */}
          <Link className={classes.Link} to="/node/316">
            {T("Learn More", props.lang)}
            <span></span>
            {/*Learn More<span></span>*/}
          </Link>{" "}
        </ConditionalWrapper>
      </div>

      <div className={classes.Background}>
        {props.lang == "en" || props.lang == "en-gb" ? (
          <picture>
            {/*Use AVIF iamge if the browser supports it*/}
            <source
              srcSet={
                "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/newproduct3.avif"
              }
              type="image/avif"
            />
            {/*Use webp iamge if the browser supports it*/}
            <source
              srcSet={
                "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/newproduct3.webp"
              }
              type="image/avif"
            />
            {/*Fall back if the browser does not support avig*/}
            <img
              className={classes.Image}
              src="https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/newproduct3.png"
              alt={"BD7600 Box Drill"}
              loading="lazy"
            />
          </picture>
        ) : null}

        {props.lang == "es" || props.lang == "ro" || props.lang == "uk" ? (
          <picture>
            {/*Use AVIF iamge if the browser supports it*/}
            <source
              srcSet={
                "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/front_page/PL5500-7800_OP.avif"
              }
              type="image/avif"
            />
            {/*Use webp iamge if the browser supports it*/}
            <source
              srcSet={
                "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/front_page/PL5500-7800_OP.webp"
              }
              type="image/avif"
            />
            {/*Fall back if the browser does not support avig*/}
            <img
              className={classes.Image}
              src="https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/front_page/PL5500-7800_OP.png"
              alt={"BD7600 Box Drill"}
              loading="lazy"
            />
          </picture>
        ) : null}

        {props.lang == "ru" ? (
          <picture>
            {/*Use AVIF iamge if the browser supports it*/}
            <source
              srcSet={
                "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/front_page/pl5800-studio.avif"
              }
              type="image/avif"
            />
            {/*Use webp iamge if the browser supports it*/}
            <source
              srcSet={
                "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/front_page/pl5800-studio.webp"
              }
              type="image/avif"
            />
            {/*Fall back if the browser does not support avig*/}
            <img
              className={classes.Image}
              src="https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/front_page/pl5800-studio.png"
              alt={"BD7600 Box Drill"}
              loading="lazy"
            />
          </picture>
        ) : null}
      </div>
    </div>
  );
}
