import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchAgronomy = (lang) => {
  const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];
  let OriginLanguage = langArray.includes(lang) ? lang : "en-gb";
  return (dispatch) => {
    dispatch(fetchAgronomyStart());
    axios
      .get(`/agronomy/${OriginLanguage}`)
      .then((response) => {
        dispatch(fetchAgronomySuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchAgronomyFail(err));
      });
  };
};

/***********************************************************
 // Fetch All Agronomy
 ***********************************************************/
export const fetchAgronomyStart = () => {
  return {
    type: actionTypes.FETCH_AGRONOMY_START,
  };
};

export const fetchAgronomySuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_AGRONOMY_SUCCESS,
  };
};

export const fetchAgronomyFail = (error) => {
  return {
    type: actionTypes.FETCH_AGRONOMY_FAIL,
    error: error,
  };
};

/***********************************************************
 // Fetch single Agronomy
 ***********************************************************/
export const fetchAgronomySingle = (lang) => {
  const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];
  let OriginLanguage = langArray.includes(lang) ? lang : "en-gb";
  return (dispatch) => {
    dispatch(fetchAgronomySingleStart());
    axios
      .get(`/agronomy/${OriginLanguage}`)
      .then((response) => {
        dispatch(fetchAgronomySuccessSingle(response.data));
      })
      .catch((err) => {
        dispatch(fetchAgronomyFailSingle(err));
      });
  };
};

export const fetchAgronomySingleStart = () => {
  return {
    type: actionTypes.FETCH_AGRONOMY_SINGLE_START,
  };
};

export const fetchAgronomySuccessSingle = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_AGRONOMY_SINGLE_SUCCESS,
  };
};

export const fetchAgronomyFailSingle = (error) => {
  return {
    type: actionTypes.FETCH_AGRONOMY_SINGLE_FAIL,
    error: error,
  };
};
