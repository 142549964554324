import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchSerial = (serial, token) => {
  const data = { serial_number: serial, token: token };
  return (dispatch) => {
    dispatch(fetchSerialStart());

    axios
      .post("/serial", data)
      .then((response) => {
        dispatch(fetchSerialSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        if ((err = "Error: Request failed with status code 429")) {
          const timeout = {
            message: "Too many requests, please try again in 10 seconds.",
          };

          dispatch(fetchSerialSuccess(timeout));
        } else dispatch(fetchSerialFail(err));
      });
  };
};

export const fetchSerialStart = () => {
  return {
    type: actionTypes.FETCH_SERIAL_START,
  };
};

export const fetchReset = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_SERIAL_RESET,
  };
};

export const fetchSerialSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_SERIAL_SUCCESS,
  };
};

export const fetchSerialFail = (error) => {
  return {
    type: actionTypes.FETCH_SERIAL_FAIL,
    error: error,
  };
};
