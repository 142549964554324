import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
const initialState = {
  agronomy: null,
  tags: null,
  agronomySingle: {
    loading: true,
    error: false,
    data: null,
  },
  loading: true,
  error: false,
  alert: null,
};

const fetchAgronomyStart = (state) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchAgronomySuccess = (state, action) => {

  let temp = updateObject(state, {
    agronomy: action.data.agronomy,
    tags: action.data.tags,
    nidList: action.data.nidList,
    alert: action.data.alert[0],
    loading: false,
    error: false,
  });
  return temp;
};

const fetchAgronomyFail = (state) => {

  return updateObject(state, {
    loading: false,
    error: true,
  });
};
const fetchAgronomySingleStart = (state) => {
  return updateObject(state, {
    agronomySingle: {
      loading: true,
    },
  });
};

const fetchAgronomySuccessSingle = (state, action) => {
  let temp = updateObject(state, {
    agronomySingle: {
      loading: false,
      error: false,
      data: action.data.agronomy,
    },
  });
  return temp;
};

const fetchAgronomyFailSingle = (state) => {
  return updateObject(state, {
    agronomySingle: {
      loading: false,
      error: true,
    },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_AGRONOMY_START:
      return fetchAgronomyStart(state, action);
    case actionTypes.FETCH_AGRONOMY_SUCCESS:
      return fetchAgronomySuccess(state, action);
    case actionTypes.FETCH_AGRONOMY_FAIL:
      return fetchAgronomyFail(state, action);
    case actionTypes.FETCH_AGRONOMY_SINGLE_START:
      return fetchAgronomySingleStart(state, action);
    case actionTypes.FETCH_AGRONOMY_SINGLE_SUCCESS:
      return fetchAgronomySuccessSingle(state, action);
    case actionTypes.FETCH_AGRONOMY_SINGLE_FAIL:
      return fetchAgronomyFailSingle(state, action);

    default:
      return state;
  }
};

export default reducer;
