import React from "react";
import classes from "./Secondary.module.scss";

import NavigationItems from "./NavigationItems/NavigationItems";

const secondary = (props) => (
  <div className={`${classes.Secondary} Secondary`}>
    <NavigationItems isAuthenticated={props.isAuth} />
  </div>
);

export default secondary;
