import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  facilities: null,
  loading: true,
  error: false,
};

const fetchFacilitiesStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchFacilitiesSuccess = (state, action) => {
  let temp = updateObject(state, {
    header: [
      "Great Plains Manufacturing Corporate",
      "Great Plains Ag Division",
      "Land Pride Division",
      "Great Plains International Division",
      "Great Plains Trucking Division",
    ],
    facilities: action.data.facilities,
    loading: false,
    error: false,
    banner: action.data,
  });
  return temp;
};

const fetchFacilitiesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FACILITIES_START:
      return fetchFacilitiesStart(state, action);
    case actionTypes.FETCH_FACILITIES_SUCCESS:
      return fetchFacilitiesSuccess(state, action);
    case actionTypes.FETCH_FACILITIES_FAIL:
      return fetchFacilitiesFail(state, action);

    default:
      return state;
  }
};

export default reducer;
