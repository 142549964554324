import classes from "../Footlight.module.scss";
import React from "react";
import { uuidv4 } from "../../../shared/utility";
import { Link } from "react-router-dom";
let key = uuidv4();

export default React.memo(function DealerSearchInt(props) {
  return (
    <>
      <div className={`${classes.FootlightChildWrapper} FootlightChildWrapper`}>
        <div className={`${classes.InnerWrapper} InnerWrapper`}>
          <Link to={`/${props.lang}/customer-stories`}>
            {" "}
            <h3 className={`${classes.Header} Header`}>Customer Stories</h3>
            <h6 className={`${classes.Customer} Customer`}>
              {" "}
              See What Customers Have To Saying About Great Plains Ag!
            </h6>
          </Link>
          <div className={`${classes.Content} Content`}>
            {" "}
            <div className={`${classes.ImageWrapper} ImageWrapper`}>
              <Link to={`/${props.lang}/customer-stories`}>
                {" "}
                <img
                  className={`${classes.Image} Image`}
                  src={
                    "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/footlight2.jpeg"
                  }
                  alt={"Customer Product Image"}
                  loading={"lazy"}
                />
              </Link>
            </div>
          </div>
          <p className={`${classes.Qoute} Qoute`}>
            "We have total confidence in the 1206NT Drill. We will no-till
            anything from alfalfa to soybeans to pasture grasses in any
            conditions"{" "}
            <span className={`${classes.QouteName} QouteName`}>
              - Great Plains Ag Customer
            </span>
          </p>

          <div className={`${classes.Buttons} Buttons`}>
            <Link to={`/en/customer-stories`}>
              <button className={`${classes.Button} Button`}>
                View Testimonials
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
});
