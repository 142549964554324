import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchFeatures = (lang) => {
  const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];
  let OriginLanguage = langArray.includes(lang) ? lang : "en-gb";

  return (dispatch) => {
    dispatch(fetchFeaturesStart());
    axios
      .get(`/product-features/${OriginLanguage}`)
      .then((response) => {
        dispatch(fetchFeaturesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchFeaturesFail(err));
      });
  };
};

/***********************************************************
 // Fetch All Features
 ***********************************************************/
export const fetchFeaturesStart = () => {
  return {
    type: actionTypes.FETCH_FEATURES_START,
  };
};

export const fetchFeaturesSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_FEATURES_SUCCESS,
  };
};

export const fetchFeaturesFail = (error) => {
  return {
    type: actionTypes.FETCH_FEATURES_FAIL,
    error: error,
  };
};
