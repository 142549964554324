import * as actions from "../../store/actions/index";
import {connect} from "react-redux";
import React, {Fragment, Suspense} from "react";
import Spinner from "../../components/UI/Spinner/Spinner";
import Squares from "../../components/Squares/Squares";
import Banners from "./../../components/Banners/Banners";
import HomeBanner from "./HomeBanner/HomeBanner";
import ErrorDisplay from "./../../components/ErrorDisplay/ErrorDisplay";
import ProductCarousel from "../../components/ProductCarousel/ProductCarousel";
import {Helmet} from "react-helmet";
import TestimonialPreview from "../../components/TestimonialPreview/TestimonialPreview";

import T from "./../../hoc/Language/translate";

//This keeps TestimonialPreviews from loading until you scroll down

class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    //This is to check if a component is mounted. It helps remove event listeners and timeouts so we dont get memory leaks
    this._isMounted = false;
  }
  componentDidMount() {

    const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"]
    let location = window.location.pathname.split("/")[1]
    let Lang = location.length && langArray.includes(location) ? location : this.props.origin.language
 //  let Lang = window.location.pathname.split("/").[1] ? window.location.pathname.split("/").[1] : this.props.origin.language
    //Fetch the home content
    this.props.onFetchHome(Lang);
    //This is to check if a component is mounted. It helps remove event listeners and timeouts so we dont get memory leaks
    this._isMounted = true;
  }

  componentWillUnmount() {
    //This is to check if a component is mounted. It helps remove event listeners and timeouts so we dont get memory leaks
    this._isMounted = false;
  }
  render() {
    //will render a loading icon if the data isn't loaded in yet
    let content = <Spinner />;
    let banners,
      squares,
      homeBanner,
      productCarousel,
      testimonialPreview = null;

    //Dont load content unless its done loading and no error
    if (!this.props.loading) {
      if (!this.props.error) {
        try {
          banners = (
            <Banners
              banners={this.props.banners}
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              useKeyboardArrows={true}
              autoPlay={true}
              stopOnHover={true}
              interval={7000}
              transitionTime={350}
              lang={this.props.origin.language}
              auth={this.props.auth.auth}
            />
          );

          //Only display the Carousel if its not spanish or romanian
          productCarousel =
            this.props.origin.language !== "es" &&
            this.props.origin.language !== "ro" ? (
              <ProductCarousel
                products={this.props.carousel}
                origin={this.props.origin}
                auth={this.props.auth.auth}
              />
            ) : null;
          homeBanner = (
            <HomeBanner
              lang={this.props.origin.language}
              content={this.props.Home[0].teaser}
              title={this.props.Home[0].title}
              auth={this.props.auth.auth}
            />
          );
          squares = (
            <Squares
              squares={this.props.squares}
              lang={this.props.origin.language}
              auth={this.props.auth.auth}
            />
          );

          //Only display the testimonial Preview if its english based language.
          testimonialPreview =
            this.props.origin.language === "en" ||
            this.props.origin.language === "en-gb" ? (
              <TestimonialPreview
                {...this.props.testimonial}
                lang={this.props.origin.language}
              />
            ) : null;

          content = (
            <Fragment>
              {this._isMounted ? banners : null}
              {this._isMounted ? productCarousel : null}
              {this._isMounted ? homeBanner : null}
              {this._isMounted ? squares : null}
              {
                <Suspense fallback={<div>Loading...</div>}>
                  {testimonialPreview}
                </Suspense>
              }
              {/*{this._isMounted ? : null}*/}
            </Fragment>
          );
        } catch (err) {
          console.log(err);
          content = <ErrorDisplay />;
        }
      } else {
        content = <ErrorDisplay />;
      }
    }

    return (
      <Fragment>
        {/*Helmet is used to set the title in the tab and add meta data (seo)*/}
        <Helmet>
          <title>
            {T("Home", this.props.origin.language)} | Great Plains Ag
          </title>
          <meta
            name="description"
            content="Great Plains is a leading producer of Grain Drills, and is also recognized across North America as a leader in Vertical Tillage, Hybrid Tillage, and Conventional Tillage Equipment, Planters, and Compact Drills."
          />
        </Helmet>
        {content}
      </Fragment>
    );
  }
}

//Map the state to the props from redux
const mapStateToProps = (state) => {
  return {
    ...state.home,
    origin: state.origin,
    auth: { ...state.auth },
  };
};

//Fetch the redux state
const mapDispatchToProps = (dispatch) => {
  return {
    onFetchHome: (lang) => dispatch(actions.fetchHome(lang)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
