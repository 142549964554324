import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  banners: null,
  loading: true,
  error: false,
};

const fetchBannersStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchBannersSuccess = (state, action) => {
  let temp = updateObject(state, {
    banners: action.data,
    loading: false,
    error: false,
  });
  return temp;
};

const fetchBannersFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BANNERS_START:
      return fetchBannersStart(state, action);
    case actionTypes.FETCH_BANNERS_SUCCESS:
      return fetchBannersSuccess(state, action);
    case actionTypes.FETCH_BANNERS_FAIL:
      return fetchBannersFail(state, action);
    default:
      return state;
  }
};

export default reducer;
