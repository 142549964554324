import * as actionTypes from "./actionTypes";
import axios from "axios";
import {getEnvironment} from "../../shared/utility";
import CountriesJson from "../../config/json/countires.json";
//This is all the supported langs
const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"]

export const fetchOrigin = (lang) => {
  //If the origin call has a lang slug set it to that lang (this is for the URL like es/home)

  let OriginLanguage = langArray.includes(lang) ? lang : undefined;

  //This is all the other origin stuff we need like EU = 1 ? 0
  let origin;
  //Fire off the call to origin
  return (dispatch) => {
    dispatch(fetchOriginStart());
    axios
      .get(`${getEnvironment()}/origin`)
      .then(function (response) {
        //If origin dose not fail
        origin = response.data;
        //If we dint get a lang in the origin call then look at the location of the user and set the lang based on location ( we get one if the url has a lang like /en/home)
      if (OriginLanguage == undefined) {
          //We want to make sure if you are in the US, Canada, Ukraine, Romanian or Russia you get the lang of that country.
          if (CountriesJson[response.data.location.country]) {
            OriginLanguage = CountriesJson[response.data.location.country];
          } else if(navigator.language){
            //This is checking the user preference in the browser and if it matches our supported langs it sets it to them
            OriginLanguage = langArray.includes(navigator.language.split("-")[0]) ? navigator.language.split("-")[0] : "en-gb"
          } else OriginLanguage = "en-gb" //Finally a redundant set
          //If you arent in the location list then you get en-gb (like JP or French)
        }
      }).then(function (response) {
        //Then we can make a call to the nodeIndex and get the nodeIndex for that lang
        const promises = [
          axios.get(`${getEnvironment()}/utility/nodeIndex/${OriginLanguage}`),
          axios.get(`${getEnvironment()}/landing-page/${OriginLanguage}`),
        ];
        const resolvedPromises = promises.map((promise) =>
          promise.catch((error) => ({ error }))
        );
        function checkFailed(then) {
          // console.log("TESTING here");
          return function (responses) {
            const someFailed = responses.some((response) => response.error);
            if (someFailed) {
              throw responses;
            }
            return then(responses);
          };
        }
        axios
          .all(resolvedPromises)
          .then(
            checkFailed(([nodeIndex, landingPages]) => {
              // console.log(origin);
              let reverseSideBar = {};
              try {
                for (let landingPage in landingPages.data.landingPages) {
                  landingPages.data.landingPages[
                    landingPage
                  ].content_for_sidebar_menu.forEach((nid) => {
                    if (!reverseSideBar[nid]) {
                      reverseSideBar[nid] = [];
                    }
                    reverseSideBar[nid].push(landingPage);
                  });
                }
              } catch (e) {
                console.log("an error here ", e);
              }
              dispatch(
                fetchOriginSuccess({
                  ...origin,
                  ...nodeIndex.data,
                  landingPages: { ...landingPages.data.landingPages },
                  reverseSideBar: reverseSideBar,
                  language: OriginLanguage,  //Change the sites lang here
                })
              );
            })
          )
          .catch((err) => {
            console.log(err);
          });
      })
      .catch(function (error) {
        console.log("an error here ", error);
      })
      .then(function () {
        // always executed
      });
  };
  // }
};
export const fetchOriginStart = () => {
  return {
    type: actionTypes.FETCH_ORIGIN_START,
  };
};

export const fetchOriginSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_ORIGIN_SUCCESS,
  };
};

export const fetchOriginFail = (error) => {
  return {
    type: actionTypes.FETCH_ORIGIN_FAIL,
    error: error,
  };
};
//ORIGIN UPDATE will update the sites language preferences en => ru. Thats all it dose
export const fetchOriginUpdate = (data) => {
  return {
    language: data.language,
    type: actionTypes.FETCH_ORIGIN_UPDATE,
  };
};

export const fetchLanguageSiteChange = (data) => {

  return (dispatch) => {
    dispatch(fetchOriginStart());
    const promises = [
      axios.get(`${getEnvironment()}/utility/nodeIndex/${data.language}`),
      axios.get(`${getEnvironment()}/landing-page/${data.language}`),
    ];

    const resolvedPromises = promises.map((promise) =>
      promise.catch((error) => ({ error }))
    );

    function checkFailed(then) {
      // console.log("TESTING here");
      return function (responses) {
        const someFailed = responses.some((response) => response.error);
        if (someFailed) {
          throw responses;
        }
        return then(responses);
      };
    }
    axios
      .all(resolvedPromises)
      .then(
        checkFailed(([nodeIndex, landingPages]) => {
          let reverseSideBar = {};
          try {
            for (let landingPage in landingPages.data.landingPages) {
              landingPages.data.landingPages[
                landingPage
              ].content_for_sidebar_menu.forEach((nid) => {
                if (!reverseSideBar[nid]) {
                  reverseSideBar[nid] = [];
                }
                reverseSideBar[nid].push(landingPage);
              });
            }
          } catch (e) {
            console.log("an error here ", e);
          }
          dispatch(
            fetchOriginSuccess({
              ...nodeIndex.data,
              landingPages: { ...landingPages.data.landingPages },
              reverseSideBar: reverseSideBar,
            })
          );
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };
};
