import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import classes from "./Layout.module.scss";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import Footlight from "./../../containers/Footlight/Footlight";
import "../../hoc/AlertAtTheTopOfThePage/AlertAtTheTopOfThePage"
import AlertAtTheTopOfThePage from "../AlertAtTheTopOfThePage/AlertAtTheTopOfThePage";
const Footer = lazy(() => import("../../components/Footer/Footer"));

// import Footlight from "./../../containers/Footlight/Footlight";

class Layout extends React.PureComponent {
  constructor(props) {
    super(props);
    this._isMounted = false;

    this.state = {
      showSideDrawer: false,
      showMore: false,
    };
  }
  removeOnResize = () => {
    if (this._isMounted) {
      if (window.innerWidth >= 979 && this.state.showSideDrawer) {
        this.setState({
          showSideDrawer: false,
          showMore: false,
        });
      }
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.removeOnResize.bind(this));
    this._isMounted = true;
    this.removeOnResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.removeOnResize.bind(this));
    this._isMounted = false;
  }

  sideDrawerClosedHandler = () => {
    if (this._isMounted) {
      this.setState({
        showSideDrawer: false,
        showMore: false,
      });
    }
  };

  sideDrawerToggleHandler = () => {
    if (this._isMounted) {
      this.setState((prevState) => {
        return {
          showSideDrawer: !prevState.showSideDrawer,
          showMore: false,
        };
      });
    }
  };

  showMoreToggleHandler = () => {
    if (this._isMounted) {
      this.setState((prevState) => {
        return { showMore: !prevState.showMore };
      });
    }
  };

  render() {
    return (
      <div className={`${classes.Container} Container`} key="Layout">
        <Toolbar
          drawerToggleClicked={this.sideDrawerToggleHandler}
          closed={this.sideDrawerClosedHandler}
          open={this.state.showSideDrawer}
        />

        <SideDrawer
          open={this.state.showSideDrawer}
          closed={this.sideDrawerClosedHandler}
          moreOpen={this.state.showMore}
          moreToggle={this.showMoreToggleHandler}
          key={"SideDrawer"}
        />
<AlertAtTheTopOfThePage isOn={false} />
        <main
          onClick={this.sideDrawerClosedHandler}
          className={`${classes.Content} Content`}
          key={"MAin"}
        >
          {this.props.children}
        </main>
        <Footlight social={this.props.social} key={"footlightkey"} />

        {this._isMounted ? (
          <Suspense fallback={<div>Loading...</div>} key={"FootLightSuspense"}>
            <Footer closed={this.sideDrawerClosedHandler} key={"FooterKey"} />
          </Suspense>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(Layout);
