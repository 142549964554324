import classes from "../Footlight.module.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class DealerSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      city: "",
      zip: "",
      state: "",
      filter: "all",
      country: "U.S. Domestic",
    };
    this._isMounted = false;
  }
  render() {
    return (
      <>
        <div
          id={`${classes.DealerLocator} DealerLocator`}
          className={`${classes.FootlightChildWrapper} FootlightChildWrapper`}
        >
          <div className={`${classes.InnerWrapper} InnerWrapper`}>
            <h3 className={`${classes.Header} Header`}> Dealer Search</h3>
            <div className={`${classes.Content} Content`}>
              <form
                className={`locator`}
                id="dealerLocatorForm"
                method="post"
                name="dealerLocatorForm"
                action=""
              >
                <div className={`${classes.FormWrapper} FormWrapper`}>
                  <label htmlFor="Zip">
                    Enter 5-digit US ZIP or 6-character CA postal code:
                  </label>
                  <p>
                    <input
                      id="Zip"
                      name="Zip"
                      type="text"
                      placeholder="ZIP Code"
                      onChange={(e) =>
                        this.setState({ zip: `${e.target.value}` })
                      }
                      defaultValue={this.state.zip}
                      size="10"
                      maxLength="7"
                      className={"Zipcode"}
                      key={"ZipKey"}
                    />
                  </p>
                  <p>
                    <strong>- OR -</strong>
                  </p>
                  <label htmlFor={"City"}>
                    Enter city and select state or province:
                  </label>
                  <p>
                    <input
                      name="City"
                      type="text"
                      id="City"
                      onChange={(e) =>
                        this.setState({ city: `${e.target.value}` })
                      }
                      defaultValue={this.state.city}
                      key={"CityKey"}
                    />

                    <label htmlFor={"State"}>
                      Choose your state or province:
                    </label>
                    <select
                      className={"State"}
                      name="State"
                      id="State"
                      onChange={(e) =>
                        this.setState({ state: `${e.target.value}` })
                      }
                      defaultValue={this.state.state}
                      key={"STATEKey"}
                    >
                      <option defaultValue="selected" value="">
                        Choose Your State
                      </option>
                      <option value="AL">Alabama - (AL)</option>
                      <option value="AK">Alaska - (AK)</option>
                      <option value="AB">Alberta, CA - (AB)</option>
                      <option value="AS">American Samoa - (AS)</option>
                      <option value="AZ">Arizona - (AZ)</option>
                      <option value="AR">Arkansas - (AR)</option>
                      <option value="BC">British Columbia, CA - (BC) </option>
                      <option value="CA">California - (CA)</option>
                      <option value="CO">Colorado - (CO)</option>
                      <option value="CT">Connecticut - (CT)</option>
                      <option value="DE">Delaware - (DE)</option>
                      <option value="DC">District Of Columbia - (DC) </option>
                      <option value="FM">
                        Federated States Of Micronesia - (FM){" "}
                      </option>
                      <option value="FL">Florida - (FL)</option>
                      <option value="GA">Georgia - (GA)</option>
                      <option value="GU">Guam Gu - (GU)</option>
                      <option value="HI">Hawaii - (HI)</option>
                      <option value="ID">Idaho - (ID)</option>
                      <option value="IL">Illinois - (IL)</option>
                      <option value="IN">Indiana - (IN)</option>
                      <option value="IA">Iowa - (IA)</option>
                      <option value="KS">Kansas - (KS)</option>
                      <option value="KY">Kentucky - (KY)</option>
                      <option value="LA">Louisiana - (LA)</option>
                      <option value="ME">Maine - (ME)</option>
                      <option value="MB">Manitoba, CA - (MB)</option>
                      <option value="MH">Marshall Islands - (MH)</option>
                      <option value="MD">Maryland - (MD)</option>
                      <option value="MA">Massachusetts - (MA)</option>
                      <option value="MI">Michigan - (MI)</option>
                      <option value="MN">Minnesota - (MN)</option>
                      <option value="MS">Mississippi - (MS)</option>
                      <option value="MO">Missouri - (MO)</option>
                      <option value="MT">Montana - (MT)</option>
                      <option value="NE">Nebraska - (NE)</option>
                      <option value="NV">Nevada - (NV)</option>
                      <option value="NB">New Brunswick, CA - (NB) </option>
                      <option value="NH">New Hampshire - (NH)</option>
                      <option value="NJ">New Jersey - (NJ)</option>
                      <option value="NM">New Mexico - (NM)</option>
                      <option value="NY">New York - (NY)</option>
                      <option value="NL">
                        Newfoundland and Labrador, CA - (NL){" "}
                      </option>
                      <option value="NC">North Carolina - (NC)</option>
                      <option value="ND">North Dakota - (ND)</option>
                      <option value="MP">
                        Northern Mariana Islands - (MP){" "}
                      </option>
                      <option value="NT">
                        Northwest Territories, CA - (NT){" "}
                      </option>
                      <option value="NS">Nova Scotia, CA - (NS)</option>
                      <option value="NU">Nunavut, CA - (NU)</option>
                      <option value="OH">Ohio - (OH)</option>
                      <option value="OK">Oklahoma - (OK)</option>
                      <option value="ON">Ontario, CA - (ON)</option>
                      <option value="OR">Oregon - (OR)</option>
                      <option value="PW">Palau - (PW)</option>
                      <option value="PA">Pennsylvania - (PA)</option>
                      <option value="PE">
                        Prince Edward Island, CA - (PE){" "}
                      </option>
                      <option value="PR">Puerto Rico - (PR)</option>
                      <option value="QC">Quebec, CA - (QC)</option>
                      <option value="RI">Rhode Island - (RI)</option>
                      <option value="SK">Saskatchewan, CA - (SK)</option>
                      <option value="SC">South Carolina - (SC)</option>
                      <option value="SD">South Dakota - (SD)</option>
                      <option value="TN">Tennessee - (TN)</option>
                      <option value="TX">Texas - (TX)</option>
                      <option value="UT">Utah - (UT)</option>
                      <option value="VT">Vermont - (VT)</option>
                      <option value="VI">Virgin Islands - (VI)</option>
                      <option value="VA">Virginia - (VA)</option>
                      <option value="WA">Washington - (WA)</option>
                      <option value="WV">West Virginia - (WV)</option>
                      <option value="WI">Wisconsin - (WI)</option>
                      <option value="WY">Wyoming - (WY)</option>
                      <option value="YT">Yukon, CA - (YT)</option>
                    </select>
                  </p>
                  <label htmlFor={"filter"}>Select dealer type:</label>
                  <p>
                    <select
                      className="DealerType"
                      name="filter"
                      id="filter"
                      onChange={(e) =>
                        this.setState({ filter: `${e.target.value}` })
                      }
                      defaultValue={this.state.filter}
                    >
                      <option value="all" defaultValue="selected">
                        All Great Plains Dealers
                      </option>
                      <option value="3"> Planters</option>
                      <option value="1"> Drills</option>
                      <option value="4"> Tillage</option>
                      <option value="6"> Compact Drills</option>
                      <option value="5"> Precision Application</option>
                      <option value="kcl">
                        Kubota Canada Ag (Canada Only)
                      </option>
                    </select>
                  </p>
                </div>
              </form>
            </div>
            <div className="Notice" style={{ fontSize: "12px" }}>
              {/*This text was added for Kubota*/}
              <p>
                This site is protected by reCAPTCHA, and the Google{" "}
                <a target={`_blank`} href="https://policies.google.com/privacy">
                  Privacy Policy{" "}
                </a>
                and{" "}
                <a target={`_blank`} href="https://policies.google.com/terms">
                  {" "}
                  Terms of Service{" "}
                </a>
                apply. By clicking ‘Search’ you agree to reading and accepting
                the{" "}
                <a
                  href="https://www.greatplainsag.com/en/article/13490/great-plains-manufacturing-website-terms-of-use"
                  target="_blank"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href="https://www.greatplainsag.com/en/article/9225/great-plains-privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>

            <div className={`${classes.Buttons} Buttons`}>
              {this.state.zip ? (
                <Link
                  to={`/dealer-search/${this.state.zip}/${this.state.filter}`}
                >
                  <button
                    className={`${classes.Button} Button`}
                    type="submit"
                    value="Submit"
                  >
                    Search
                  </button>{" "}
                </Link>
              ) : (
                <Link
                  to={`/dealer-search/${this.state.city}/${this.state.state}/${this.state.filter}`}
                >
                  <button
                    className={`${classes.Button} Button`}
                    type="submit"
                    value="Submit"
                  >
                    Search
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
