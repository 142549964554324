import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchFieldAdjustment = (lang) => {
  const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];
  let OriginLanguage = langArray.includes(lang) ? lang : "en-gb";

  return (dispatch) => {
    dispatch(fetchFieldAdjustmentStart());
    axios
      .get(`/field-adjustment/${OriginLanguage}`)
      .then((response) => {
        dispatch(fetchFieldAdjustmentSuccess(response.data));
      })
      .catch((err) => {
        console.log("failed the FieldAdjustment fetch", err);
        dispatch(fetchFieldAdjustmentFail(err));
      });
  };
};

/***********************************************************
 // Fetch All FieldAdjustment
 ***********************************************************/
export const fetchFieldAdjustmentStart = () => {
  return {
    type: actionTypes.FETCH_FIELD_ADJUSTMENT_START,
  };
};

export const fetchFieldAdjustmentSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_FIELD_ADJUSTMENT_SUCCESS,
  };
};

export const fetchFieldAdjustmentFail = (error) => {
  return {
    type: actionTypes.FETCH_FIELD_ADJUSTMENT_FAIL,
    error: error,
  };
};
