import React, { useEffect, useState } from "react";
import classes from "./Square.module.scss";
import Link from "react-router-dom/Link";
import shaders from "./../../../shared/scss/variables/shading";
import Parser from "html-react-parser";
import T from "../../../hoc/Language/translate";
import ConditionalWrapper from "../../../hoc/ConditionalWrapper/ConditionalWrapper";

export default function Square(props) {
  const mediaMatch = window.matchMedia("(max-width: 768px)");
  const [matches, setMatches] = useState(mediaMatch.matches);
  let imageUrl = props.square.square_image.values[0].images['1300px_uri'];

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  if (matches) {
    imageUrl = props.square.square_image.values[0].images['500px_uri'];
  }

  let classList = [classes.Wrapper];
  //setting background style information here because I need to pass variable to it
  let shades = shaders();
  let shading = shades[props.square.categorization.field_background_shading.values[0].name];
  let lang = props.lang;

  let URL =
    props.square.read_more_link.value[0] == "/"
      ? `${props.square.read_more_link.value}`
      : `/${props.square.read_more_link.value}`;

  let NewURL = "/" + lang + URL;

  let style = {
    backgroundImage: `linear-gradient(${shading}, ${shading} ),  url(${imageUrl})`,
    backgroundPosition: `${props.square.vertical_alignment.value} ${props.square.horizontal_alignment.value}`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };



  // adds classes to the square based on their position in the array
  if (props.full === true) {
    classList.push(classes.Full);
    classList.push(
      props.square.content_location === "left" && classes.FullLeft
    );
    classList.push(
      props.square.content_location === "right" && classes.FullRight
    );
    classList.push(
      props.square.content_location === "center" && classes.FullCenter
    );
  } else {
    classList.push(classes.Small);
    classList.push(props.side === "left" ? classes.Left : classes.Right);
  }

  let classString = "";
  classList.forEach((element) => {
    classString += element + " ";
  });

  return (
    <div className={`${classString} String`} style={style}>
         <div className={`${classes.Content} Content`}>
           <ConditionalWrapper
          condition={props.auth}
          wrapper={(children) => (
            <div className={"HoverOver"}>
              <a
                className={"EditThisLink"}
                target="_blank"
                href={`https://cms.greatplainsag.com/${props.lang}/node/${props.square.nid}/edit`}
              >
                Edit This Content
              </a>
              {children}
            </div>
          )}
        >
          <div>
            {props.square.icon ? (
              <img
                className={`${classes.Icon} Icon`}
                src={props.square.icon.values[0].url}
                alt={`${Parser(props.square.title)} Icon`}
                loading={"lazy"}
              />
            ) : null}
          </div>{" "}
          <div className={`${classes.Title} Title`}>
            {/*{console.log(`/${props.square.field_link_square}`)}*/}
            {Parser(props.square.title)}
          </div>
          <div className={`${classes.Subtitle} Subtitle`}>
            {Parser(props.square.caption.value)}
          </div>
          {!props.square.read_more_link.value.includes("http") ? (
            <Link
              className={`${classes.Button} Button`}
              to={NewURL}
            >
              <button className={"Button"}>
                {T("LEARN MORE", props.lang)}
              </button>
            </Link>
          ) : (
            <a
              className={`${classes.Button} Button`}
              target="_blank"
              href={ props.square.read_more_link.value}
            >
              <button>{T("LEARN MORE", props.lang)}</button>
            </a>
          )}
        </ConditionalWrapper>
      </div>
    </div>
  );
}
