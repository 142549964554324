export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const buildClassString = (classArray) => {
  let returnable = "";
  classArray.forEach((element) => {
    returnable += element + " ";
  });
  return returnable;
};

// Lifted from https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
// For use creating unique keys.
export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getEnvironment = () =>
    process.env.NODE_ENV != "development"
    ? "https://" + "api.greatplainsag.com" + "/v1"
    : "http://localhost:8000/v1";

