import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  field_adjustement: null,
  loading: true,
  error: false,
};

const fetchFieldAdjustmentStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchFieldAdjustmentSuccess = (state, action) => {
  return updateObject(state, {
    field_adjustement: action.data,
    loading: false,
    error: false,
  });
};

const fetchFieldAdjustmentFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FIELD_ADJUSTMENT_START:
      return fetchFieldAdjustmentStart(state, action);
    case actionTypes.FETCH_FIELD_ADJUSTMENT_SUCCESS:
      return fetchFieldAdjustmentSuccess(state, action);
      state, action;
    case actionTypes.FETCH_FIELD_ADJUSTMENT_FAIL:
      return fetchFieldAdjustmentFail(state, action);
    default:
      return state;
  }
};

export default reducer;
