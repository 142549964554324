import classes from "./NotFound.module.scss";
import React from "react";
import { Helmet } from "react-helmet";

import Link from "react-router-dom/Link";

export default function ContentTemplate() {
  let content;

  /* Copy the contents of this variable as the basis for your page-level components. */
  content = (
    <div id={`ContentMainWrapper`} className={`ContentMainWrapper`}>
      <div id={`ContentHeader`} className={`ContentHeader`}>
        {/*spans in any headers change the header color to orange. */}
        <h1>
          404: <span>Requested Content</span> Was Not Found
        </h1>
      </div>
      <div id={`ColumnWrapper`} className={`Wrapper ColumnWrapper`}>
        <div id={`ContentColumn`} className={`ContentColumn`}>
          <div id={`BodyContent`} className={`BodyContent`}>
            <div className={classes.Content}>
              Apologies, but the content you are looking for is not available on
              this site. Please consider trying out our{" "}
              <Link to={`/search`}>Site Search</Link>.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <Helmet>
        <title>404 Not Found | Great Plains Ag</title>
      </Helmet>
      {content}
    </div>
  );
}
