import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LinkSwitch from "./../../LinkSwitch/LinkSwitch";
import iconClasses from "../../Navigation/Icons.module.scss";
import classes from "./../SideDrawer.module.scss";
import { uuidv4 } from "../../../shared/utility";

// import NavigationItem from './NavigationItem/NavigationItem';

class NavigationItems extends Component {
  render() {
    //will render a loading icon if the data isn't loaded in yet
    let navItems = <div>loading</div>;
    if (!this.props.loading && this.props.language) {
      this.props.navItems.forEach((navList) => {
        if (navList.purpose == this.props.type) {
          navItems = navList.languages[this.props.language].links.map(
            (element, index) => {
              if (element.showInHeader) {
                let linkClass = classes.Regular;
                if (element.icon != null) {
                  linkClass = `${classes.IconLink} ${
                    iconClasses[element.icon + "SideDrawer"]
                  }`;
                }
                //@kyler: made the following changes because of double-nested <li> tags. Please review to make sure this is
                // sound. It's kinda messed up because we need <LinkSwitch /> to render the <li> in every instance, except
                // this one. Here we need the li to wrap <LinkSwitch/> because of the on-click handler. That's why I used a
                // span instead, so we don't have double <li>s. So, now, right here, we end up with
                // <ul><span><li></li></span></ul>, which offends my OCD something terrible, and throws a warning in the
                // console. At least I think this is where the warning is getting thrown. Anyway, I don't know what to do
                // about it.

                //btw, I started using uuid for keys because I was reading the the indexes from map() were unreliable. uuid
                // will always be unique.
                let key = uuidv4();

                // return (< key={index} onClick={this.props.closed}><LinkSwitch linkClass={linkClass}  text={element.text}
                // link={element.link} /></>)
                return (
                  <li key={key}>
                    <LinkSwitch
                      onClick={this.props.closed}
                      linkClass={linkClass}
                      text={element.text}
                      link={element.link}
                    />
                  </li>
                );
              }
            }
          );
        }
      });
    }
    return <Fragment>{navItems}</Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    navItems: state.nav.nav.navLinks,
    language: state.origin.language,
    loading: state.nav.loading,
  };
};

export default connect(mapStateToProps)(NavigationItems);
