import React, { Fragment, useEffect, useState } from "react";
import Link from "react-router-dom/Link";
import { uuidv4 } from "../../shared/utility";
import classes from "./LinkSwitch.module.scss";
import T from "./../../hoc/Language/translate.js";
import LanguageFlipper from "./../../hoc/Language/LanguageFlipper";

export default function LinkSwitch(props) {
  const [search, setSearch] = useState("");

  const [isShown, setIsShown] = useState(false);
  const mediaMatch = window.matchMedia("(max-width: 900px)");
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  let linkClass = null;
  if (props.linkClass != null) {
    linkClass = props.linkClass;
  }
  const onChange = (event) => {
    setSearch(event.target.value);
  };

  const updateInput = (e) => {
    setSearch("");
  };

  let key = uuidv4();

  {
    if (
      props.link[0] === "/" &&
      props.link !== "/en/search" &&
      props.link !== "/en-gb/search" &&
      props.link !== "/es/search" &&
      props.link !== "/ro/search" &&
      props.link !== "/ru/search" &&
      props.link !== "/uk/search"
    ) {
      return (
        <Fragment key={key}>
          <Link className={`${linkClass} Link`} to={`${props.link}`}>
            {props.text}
          </Link>
        </Fragment>
      );
    }
    if (
      props.link == "/en/search" ||
      props.link == "/en-gb/search" ||
      props.link == "/es/search" ||
      props.link == "/ro/search" ||
      props.link == "/ru/search" ||
      props.link == "/uk/search"
    ) {
      return (
        <>
          <div
            id={`HeaderSearchBlock`}
            className={`${classes.HeaderSearchBlock} HeaderSearchBlock`}
          >
            <form className={`${classes.SearchForm} SearchForm`}>
              <label
                className={`${classes.SearchLabel} SearchLabel`}
                htmlFor={"Search"}
              >
                Search
              </label>
              <input
                className={`${classes.Search} Search`}
                type="text"
                name="Search"
                // placeholder="Search"
                placeholder={T("Search", props.link.split("/")[1])}
                value={search}
                onChange={onChange}
                maxLength="256"
              />
              <Link
                className={`${classes.ButtonAnchor} Link`}
                to={`${props.link}/${search}`}
              >
                <input
                  name="serach"
                  onClick={updateInput}
                  type="submit"
                  value="Search"
                  className={`${classes.SubmitButton} SubmitButton`}
                  placeholder=""
                />
              </Link>
            </form>
          </div>{" "}
          <div className={classes.Moblie}>
            {/*This removes it when we go to desktop so it dosent fire off 2 times*/}
            {matches && <LanguageFlipper moblie={true} />}
          </div>
        </>
      );
    } else
      return (
        <Fragment key={key}>
          <a className={linkClass} target="_blank" href={props.link}>
            {props.text}
          </a>
        </Fragment>
      );
  }
}
