import classes from "../Footlight.module.scss";
import React, { useState } from "react";
import T from "../../../hoc/Language/translate.js";
import { Link } from "react-router-dom";

//IF you are looking for the filter for RU and Uk its in the MW. We only  return Russian dealers for Russian and same for UK

function DealerSearchInt(props) {
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  let countryList = new Set();
  let provinceList = new Set();
  let cityList = new Set();
  let dealer =
    props.dealer.dealers != undefined ? props.dealer.dealers.Dealers : false;

  let filterDealer =
    dealer &&
    dealer
      .filter(function (data) {
        switch (country) {
          case "":
            return data;
          default:
            return data.categorization.country.values != undefined
              ? data.categorization.country.values[0].name == country
              : null;
        }
      })
      .filter(function (data) {
        switch (province) {
          case "":
            return data;
          default:
            return data.state != null ? data.state.value == province : null;
        }
      })
      .filter(function (data) {
        switch (city) {
          case "":
            return data;
          default:
            return data.city != null ? data.city.value == city : null;
        }
      });

  dealer &&
    filterDealer &&
    filterDealer.map(function (data) {
      data.categorization.country.values != undefined
        ? countryList.add(data.categorization.country.values[0].name)
        : null;
      data.state != null ? provinceList.add(data.state.value) : null;
      data.city != null ? cityList.add(data.city.value) : null;
    });

  function reset() {
    document.getElementById("myFormMini").reset();
    setCountry("");
    setProvince("");
    setCity("");

    // document.getElementById("myForm").reset();
  }
  function createSelectItems(set) {
    if (set.length > 0) {
      let items = [];

      set
        .sort(function (a, b) {
          return a.localeCompare(b);
        })
        .map(function (data, index) {
          // console.log(data);
        });
      for (let i = 0; i < set.length; i++) {
        items.push(
          <option key={i} value={set[i]}>
            {set[i]}
          </option>
        );
      }
      return items;
    }
  }

  function URL(country, province, city) {
    let countryLink;
    let provinceLink;
    let cityLink;

    let link = `/${props.lang}/international-dealers/country`;

    //if nothing ru/international-dealers//lang/all
    //if country and nothing ru/international-dealers/lang/country/all
    //if province  and nothing  ru/international-dealers//lang/all/province/all
    //if city and nothing ru/international-dealers//lang/all/all/city/

    switch (country.length) {
      case 0:
        countryLink = `/all`;
        break;
      default:
        countryLink = `/${country}`;
    }

    switch (province.length) {
      case 0:
        provinceLink = `/all`;
        break;
      default:
        provinceLink = `/${province}`;
    }

    switch (city.length) {
      case 0:
        cityLink = `/all`;
        break;
      default:
        cityLink = `/${city}`;
    }

    if (provinceLink == `/all` && cityLink == `/all`) {
      link = link + countryLink;
    } else {
      link = link + countryLink + provinceLink + cityLink;
    }

    return link;
  }
  return (
    <>
      <div
        id={`${classes.DealerLocator} DealerLocator`}
        className={`${classes.FootlightChildWrapper} FootlightChildWrapper`}
      >
        <div className={`${classes.InnerWrapper} InnerWrapper`}>
          <h3 className={`${classes.Header} Header`}>
            {T("Great Plains Dealers:", props.lang)}
          </h3>
          <div className={`${classes.Content} Content`}>
            <form className={`locator`} id={"myFormMini"}>
              <div className={`${classes.FormWrapper} FormWrapper`}>
                <label htmlFor={"Country"}>{T("Country", props.lang)}</label>

                <select
                  className={"Country"}
                  name="country"
                  id="country"
                  onChange={(event) => setCountry(event.target.value)}
                  defaultValue={""}
                  key={"CountryKey"}
                >
                  <option value="" disabled defaultValue>
                    {T("Select your option", props.lang)}
                  </option>

                  {createSelectItems([...countryList], "Country")}
                </select>

                <label htmlFor={"Province"}>
                  {T("State, Province, or County", props.lang)}
                </label>

                <select
                  className={"Province"}
                  name="province"
                  id="province"
                  onChange={(event) => setProvince(event.target.value)}
                  defaultValue={""}
                  key={"ProvinceKey"}
                >
                  <option value="" disabled defaultValue>
                    {T("Select your option", props.lang)}
                  </option>
                  {createSelectItems([...provinceList])}
                </select>

                <label htmlFor={"City"}>{T("City", props.lang)}</label>
                <select
                  className={"City"}
                  name="city"
                  id="city"
                  onChange={(event) => setCity(event.target.value)}
                  defaultValue={""}
                  key={"cityKey"}
                >
                  <option value="" disabled defaultValue>
                    {T("Select your option", props.lang)}
                  </option>
                  {createSelectItems([...cityList])}
                </select>
              </div>
            </form>
            {/*<button*/}
            {/*  className={`${classes.Button} Button`}*/}
            {/*  onClick={console.log("click")}*/}
            {/*>*/}
            {/*  {T("Search", props.lang)}*/}
            {/*</button>*/}
            <Link to={URL(country, province, city)}>
              <button
                className={`${classes.ButtonsSearch} Button`}
                type="submit"
                value="Submit"
              >
                {T("Search", props.lang)}
              </button>
            </Link>

            <button className={classes.ResetButton} onClick={reset}>
              {T("Reset", props.lang)}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DealerSearchInt;
