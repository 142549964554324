import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchPartsmart = () => {
    let datenow  = Date.now();
    return (dispatch) => {
        dispatch(fetchPartsmartStart());
        axios
        .get(`/partsmart/${datenow}`)
        .then((response) => {
            dispatch(fetchPartsmartSuccess(response.data.data));
        })
        .catch((err) => {
            dispatch(fetchPartsmartFail(err));
        });
    };
};

export const fetchPartsmartStart = () => {
    return {
        type: actionTypes.FETCH_PARTSMART_START,
    };
};


export const fetchPartsmartSuccess = (data) => {
    return {
        data: data,
        type: actionTypes.FETCH_PARTSMART_SUCCESS,
    };
};

export const fetchPartsmartFail = (error) => {
    return {
        type: actionTypes.FETCH_PARTSMART_FAIL,
        error: error,

    };
};

export const fetchPartsmartClear = () => {

    return {
        type: actionTypes.FETCH_PARTSMART_CLEAR,

    };
};
