import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  Locations: null,
  loading: true,
  error: false,
};

const fetchLocationsStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchLocationsSuccess = (state, action) => {
  let temp = updateObject(state, {
    Locations: action.data,
    loading: false,
    error: false,
  });
  return temp;
};

const fetchLocationsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATIONS_START:
      return fetchLocationsStart(state, action);
    case actionTypes.FETCH_LOCATIONS_SUCCESS:
      return fetchLocationsSuccess(state, action);
    case actionTypes.FETCH_LOCATIONS_FAIL:
      return fetchLocationsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
