import React from "react";
import Link from "react-router-dom/Link";
import classes from "./TestimonialPreview.module.scss";
import Parser from "html-react-parser";
import T from "./../../hoc/Language/translate";

export default React.memo(function TestimonialPreview(props) {
  let randomTest = Math.floor(
    Math.random() * Math.floor(Object.keys(props).length)
  );
  return (
    <div className={classes.Wrapper}>
      <img
        className={classes.Icon}
        src="https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/components/TestimonialPreview/quotes.svg"
        alt="Qoutes"
      />
      <h3>
        <Link className={classes.Header} to={`/${props.lang}/customer-stories`}>
          See what our customers are saying...
        </Link>
      </h3>{" "}
      <p>
        {props[randomTest] != undefined &&
        props[randomTest].field_testimonial_text != undefined &&
        props[randomTest].field_testimonial_text.length
          ? Parser(props[randomTest].field_testimonial_text)
          : null}
      </p>
      <p>
        <b>
          {props[randomTest] != undefined
            ? props[randomTest].field_customer_name
            : null}
          ,{" "}
          {props[randomTest] != undefined
            ? props[randomTest].field_customer_location
            : null}{" "}
        </b>{" "}
      </p>
      <Link className={classes.Button} to={`/${props.lang}/customer-stories`}>
        <button>{T("Read Testimonials", props.lang)}</button>
      </Link>
    </div>
  );
});
