import React from "react";
import "./AlertAtTheTopOfThePage.css";

export default function AlertAtTheTopOfThePage(props) {

  if (props.isOn) {
    return (
      <div className={"BrowserChecker"}>
        <p>
          <b>Upcoming Maintenance:</b> On Saturday, February 10, we are performing routine maintenance, and may be intermittently offline between 8am and 2pm CST.
        </p>
      </div>
    );
  } else return null;
}
