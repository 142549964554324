import React  from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./store/actions";
import loadable from '@loadable/component'
import Layout from "./hoc/Layout/Layout";
import Home from "./containers/Home/Home";
import Node from "./containers/Node/Node";
import Spinner from "./components/UI/Spinner/Spinner";
import Divisions from "./components/CompanyComps/divisions/divisions";
import Locations from "./components/locations/locations";
import ScrollToTopOfPageOnLoad from "./hoc/ScrollToTop/ScrollToTopV2";
const Products = loadable(() => import("./containers/Products/Products"));
const Product = loadable(() => import("./containers/Products/Product/Product"));
const Literature = loadable(() => import("./containers/Literature/Literature"));
const Manuals = loadable(() => import("./containers/Manuals/Manuals"));
const Article = loadable(() => import("./containers/Article/Article"));
const DealerSearch = loadable(() => import("./containers/DealerSearch/DealerSearch"));
const Search = loadable(() => import("./containers/Search/Search"));
const Login = loadable(() => import("./components/Auth/Login/Login"));
const Logout = loadable(() => import("./components/Auth/Logout/Logout"));
const Facilities = loadable(() => import("./components/CompanyComps/facilities/facilities"));
const DrillCalibrationCalculator = loadable(() => import("./components/DrillCalibrationCalculator/DrillCalibrationCalculator"));
const CompanyWebsites = loadable(() => import("./components/CompanyWebsites/CompanyWebsites"));
const DealerSeachInt = loadable(() => import("./containers/DealerSearch/DealerSearchInt/DealerSearchInt"));
const News = loadable(() => import("./containers/News/News"))
const SerialNumberSearch = loadable(()=> import("./components/serialNumberSearch/serialNumberSearch"))
const Agronomy = loadable(() => import("./components/Agronomy/Agronomy"))
const Finance = loadable(() => import("./components/Finance/Finance"))
const Partsmart = loadable(() =>
    import("./components/PartSmart/partsmartComponenet")
);



class App extends React.PureComponent {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            token: "",
        };

    }


    componentDidMount() {
        reloadOTBanner()
        //Load the origin based on the routes
        const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"]
        let location = window.location.pathname.split("/")[1]
        // here
        let lang = location.length && langArray.includes(location) ? location : undefined
        this.props.onFetchOrigin(lang);
        this.props.onFetchNav();
        // this.props.onFetchUpcomingShows(lang);
        this.props.onFetchForms(lang);


        //Load Recapatcha, need it for dealer search and Forms and login
        const scriptRecaptcha = document.createElement("script");
        scriptRecaptcha.id = "recap";
        scriptRecaptcha.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_ReCaptchaV3_DealerSearch}`;
        scriptRecaptcha.async = true;
        document.body.appendChild(scriptRecaptcha);
        this._isMounted = true;

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(decodeURIComponent(location.pathname) != decodeURIComponent(prevProps.location.pathname)){
            //if url changes scroll to the top of the page
            ScrollToTopOfPageOnLoad()
        }
    }


    componentWillUnmount() {
        this._isMounted = false;
        let Recap = document.getElementById("recap");
        if (Recap || Recap != null) {
            Recap.remove();
        }
    }


    render() {
        //always available
        let publicPaths;
        let derivedRoutes = [];
        let blindRedirects = [];
        let nodeMap = [];

        if (!this.props.origin.loading) {
            for (const key in this.props.origin.nodeMap) {
                //set Temp to BlindRedirects[index] and its index
                let temp = this.props.origin.nodeMap[key];
                // //take the empty array derivedRoutes = [] and push a Route into it
                // //  set the path to /:language/blindRedirects[indexOfBlindRedirects].source_url   example /apple-touch-icon-precomposed.png
                if (temp.content_type == "blind-redirects") {
                    if (temp.destination_url.includes("http")) {
                        let Site = temp.destination_url;
                        nodeMap.push(
                            <Route
                                exact
                                key={`derived_blind${temp.nid}`}
                                path={`${temp.source_url}`}
                                render={() => window.location.replace(Site)}
                            />
                        );
                    } else {
                        nodeMap.push(
                            <Route
                                exact
                                key={`derived_blind${temp.nid}`}
                                path={`/:language?:${temp.source_url}`}
                                component={() => (
                                    <Redirect
                                        to={`/:language?${temp.destination_url}`}
                                    />
                                )}
                            />
                        );
                    }
                } else
                    nodeMap.push(
                        <Route key="node" path="/:language?/node/:nid/" component={Node} />
                    );
            }
            for (const key in this.props.origin.blindRedirects) {
                //set Temp to BlindRedirects[index] and its index
                let temp = this.props.origin.blindRedirects[key];
                    blindRedirects.push(
                    <Route
                        // exact={temp.nid == 12237}
                        exact
                        key={`derived_blind${temp.nid}`}
                        path={`/:language?${temp.source_url}${this.props.location.search}`}
                        component={() => (
                            <Redirect
                                to={`/${this.props.origin.language}${temp.destination_url}${this.props.location.search}`}
                            />
                        )}
                    />
                );
            }

            // try {
            for (const key in this.props.origin.landingPages) {

                let lp = this.props.origin.landingPages[key];
                if (lp.urlFix) {
                    let url = lp.landing_page_url;
                    nodeMap.push(
                        <Route
                            key={`derived${lp.nid}`}
                            path={`/:language?/${url}/:nid?`}
                            component={() => <Article props={{ nodeId: lp.nid }} />}
                        />
                    );
                    nodeMap.push(
                        <Route
                            key={url}
                            exact
                            path={`/:language?/${url}`}
                            component={Article}
                        />
                    );
                } else {

                    let url = lp.landing_page_url;

                    nodeMap.push(
                        <Route
                            key={url}
                            exact
                            path={`/:language?/${url}`}
                            component={Article}
                        />
                    );
                }
            }
            let manualRoutes = [
                <Route
                    key={"manual-search"}
                    path={"/:language?/manuals/search/:query?"}
                    component={Manuals}
                />,
                <Route
                    key={"manuals-single"}
                    path={`/:language/manuals/:nid`}
                    component={Manuals}
                />,
                <Route
                    key={"manuals-from-node"}
                    path={`/:language?/manual/:nid/:title?`}
                    component={Article}
                />,
            ];
            let productRoutes = [
                <Route
                    key={"products"}
                    path={`/:language/products/:nid/:title`}
                    component={Product}
                />,
                <Route
                    key={"products"}
                    path={`/:language?/products/:nid/:title?`}
                    component={Node}
                />,
                <Route
                    key="products=home"
                    path="/:language?/implements/:country?/:family?/:productline?"
                    component={Products}
                />,
                <Route
                    key="products=home"
                    path="/:language?/implement-blocks/:country?/:family?/:productline?"
                    component={Products}
                />,

                <Route
                    key={"product-features"}
                    path={`/:language/product-features/:nid/:title`}
                    component={Article}
                />,
                <Route
                    key={"product-features-from-node"}
                    path={`/:language?/product-features/:nid/:title?`}
                    component={Node}
                />,
                <Route
                    key={"testimonials"}
                    path={`/:language/testimonials/:nid/:title`}
                    component={Article}
                />,
                <Route
                    key={"testimonials"}
                    path={`/:language?/testimonials/:nid/:title?`}
                    component={Article}
                />,
                <Route
                    key={"video"}
                    path={`/:language?/video/:nid/:title?`}
                    component={Article}
                />,
            ];
            let newsRoutes = [

                <Route key="news" path="/:language/391/news" component={News} />,
                <Route
                    key="news"
                    path="/:language/391/news/news-region-list"
                    component={News}
                />,
                <Route
                    key={"news-single"}
                    path={`/:language/news/:nid/:title`}
                    component={Article}
                />,
                <Route
                    key="news-from-node"
                    path="/:language?/news/:nid/:title?"
                    component={Node}
                />,
            ];
            let formRoutes = [
                <Route
                    key={"forms"}
                    path={`/:language/forms/:nid/:title`}
                    component={Article}
                />,
                <Route
                    key={"form-single"}
                    path={`/:language?/forms/:nid/:title?`}
                    component={Node}
                />,
            ];
            let agronomyRoutes = [
                <Route
                    key={"agronomy"}
                    path={`/agronomy/:nid?/:title?`}
                    component={Agronomy}
                />,
                <Route
                    key={"agronomy"}
                    path={`/:language/agronomy/:nid?/:title?`}
                    component={Agronomy}
                />,

            ];
            publicPaths = [
                ...agronomyRoutes,
                <Route
                    // exact={temp.nid == 12237}
                    exact
                    key={`partsmartRedirect`}
                    path={`/partsmart`}
                    component={() => (
                        <Redirect
                            to={`/${this.props.origin.language}/partsmart`}
                        />
                    )}
                />,
                <Route
                    key="calculator"
                    path="/calculator"
                    component={DrillCalibrationCalculator}
                />,
                <Route
                    key="drill-calibration-calculator"
                    path="/drill-calibration-calculator"
                    component={DrillCalibrationCalculator}
                />,
                <Route
                    key="drill-calibration-calculator"
                    path="/:language?/drill-calibration-calculator"
                    component={DrillCalibrationCalculator}
                />,
                ...nodeMap,
                ...blindRedirects,
                <Route
                    key="literature"
                    path="/:language?/literature/:country/:global?/:lang?"
                    component={Literature}
                />,
                <Route
                    key={"article"}
                    path={`/:language/article/:nid/:title`}
                    component={Article}
                />,
                <Route
                    key={"Finance"}
                    path={`/en/finance/`}
                    component={Finance}
                />,
                <Route
                    key={"article-from-node"}
                    path={`/:language?/article/:nid/:title?`}
                    component={Node}
                />,
                <Route key="index-no-lang" path={`/`} exact component={Home} />,

                <Route key="index" path={`/:language?/`} exact component={Home} />,

                <Route
                    key="company-Websites"
                    exact
                    path="/:language?/company-websites"
                    component={CompanyWebsites}
                />,
                <Route
                    key="part-smart"
                    exact
                    path="/:language?/partsmart"
                    component={Partsmart}
                />,
                <Route
                    key="search"
                    path="/:language?/search/:query?"
                    component={Search}
                />,
                <Route
                    key={"SERIAL NUMBER SEARCH"}
                    path={`/:language?/serial-number-search/:number?`}
                    component={SerialNumberSearch}
                />,
                ...derivedRoutes,
                ...manualRoutes,
                ...productRoutes,
                ...newsRoutes,
                ...formRoutes,

                <Route key="findmydealer" exact path="/en/findmydealer">
                    <Redirect to="/en/dealer-search/" />
                </Route> ,
                <Route key="Spanish dealer" exact path="/es/dealer-search">
                    <Redirect  to="/es/international-dealers/country" />
                </Route> ,
                <Route key="ro dealer" exact path="/ro/dealer-search">
                    <Redirect to="/ro/international-dealers/country" />
                </Route> ,
                <Route key="uk dealer" exact path="/uk/dealer-search">
                    <Redirect to="/uk/international-dealers/country" />
                </Route> ,
                <Route key="ru dealer" exact path="/ru/dealer-search">
                    <Redirect to="/ru/international-dealers/country" />
                </Route> ,

                <Route
                    key="dealerSearch"
                    path="/:language?/dealer-search/:zip?/:city?/:state?/:filter?"
                    component={DealerSearch}
                />,
                <Route
                    key="dealerSearchintl"
                    path="/:language?/international-dealers/country/:country?/:province?/:city?/:oblast?/"
                    component={DealerSeachInt}
                />,

                <Route
                    key="Facilities"
                    path="/:language?/facilities/"
                    component={Facilities}
                />,<Route
                    key="Divisions"
                    path="/:language?/divisions/"
                    component={Divisions}
                />,
                <Route
                    key="Locations"
                    path="/:language?/corporate-locations/all"
                    component={Locations}
                />,

                <Route key="notFound" component={Node} />
            ];


        }



        //these are here for if/when users can log in through the site. could use free firebase auth plan with the company gsuite so handle logins very  easy.

        //these are only available when logged in.
        let protectedPaths = [<Route key="Logout" path={`/:language?/Logout`} component={Logout} />,
            <Route key="Login" path={`/:language?/users`} component={Logout} />,
            <Route key="Login" path={`/:language?/login`} component={Logout} />,
            <Route key="Login" path={`/:language?/user`} component={Logout} />,
        ];

        //only available when not logged in. We dont want to show login if you are logged in
        let unprotectedPaths = [<Route key="Login" path={`/:language?/users`} component={Login} />,
            <Route key="Login" path={`/:language?/login`} component={Login} />,
            <Route key="Login" path={`/:language?/user`} component={Login} />,
            <Route key="Logout" path={`/:language?/Logout`} component={Login} />,

        ];


        // We combo the unprotectedPaths or the protectedPaths with the public paths like products depending on the sessionStorage

        //Check if the token is expired
        let today = new Date();
        let expire = new Date(sessionStorage.getItem("expirationDate"));
        let booleanTest = today < expire

        let allowedPaths = ([]).concat(publicPaths)
        if(this.props.origin.VPN || process.env.NODE_ENV == "development") {
            allowedPaths = ( sessionStorage.getItem("token") && booleanTest ? protectedPaths : unprotectedPaths).concat(publicPaths)
        }

        let paths =  allowedPaths.map((element) => element)

        let myRouter;

        myRouter = this.props.origin.loading ? (
            <Spinner/>
        ) : (
            <>
                <Layout>
                    <Switch>
                        {paths}
                    </Switch>
                </Layout></>
        );

        return myRouter;
    }
}
const mapStateToProps = (state) => {
    return {
        origin: { ...state.origin },
        productsEmpty: state.products.isEmpty,
        upcomingShows: state.upcomingShows,
        auth: { ...state.auth },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchNav: () => dispatch(actions.fetchNav()),
        onFetchOrigin: (lang) => dispatch(actions.fetchOrigin(lang)),
        onFetchProducts: (familyPath, lang) =>
            dispatch(actions.fetchProducts(familyPath, lang)),
        onFetchForms: (lang) => dispatch(actions.fetchForms(lang)),

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
