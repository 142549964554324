import React from "react";
import "./BrowserChecker.css";

export default function BrowserChecker(props) {
  function get_browser() {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\bOPR\/(\d+)/);
      if (tem != null) {
        return { name: "Opera", version: tem[1] };
      }
    }
    if (window.navigator.userAgent.indexOf("Edge") > -1) {
      tem = ua.match(/\Edge\/(\d+)/);
      if (tem != null) {
        return { name: "Edge", version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: +M[1],
    };
  }

  var browser = get_browser();
  var isSupported = isSupported(browser);

  function isSupported(browser) {
    var supported = true;
    if (browser.name === "Chrome" && browser.version >= 48) {
      supported = true;
    } else if (
      (browser.name === "MSIE" || browser.name === "IE") &&
      browser.version >= 10
    ) {
      supported = false;
    } else if (browser.name === "Edge") {
      supported = true;
    }

    return supported;
  }

  if (props.debug || !isSupported) {
    return (
      <div className={"BrowserChecker"}>
        <h4>Upgrade Browser</h4>
        <p>
          Thank you for visiting Great Plains Ag! The browser you are using,{" "}
          {browser.name} {browser.version}, is no longer supported and may
          result in a sub-optimal experience on our site.{" "}
          <strong>
            We suggest upgrading to a newer, more modern browser, such as Chrome
            or Firefox.
          </strong>
        </p>
      </div>
    );
  } else return null;
}
