import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  articles: null,
  sections: null,
  loading: true,
  error: false,
};

const fetchArticlesStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchArticlesSuccess = (state, action) => {
  // console.log("about to test a thing", action.data.sections);
  let temp = updateObject(state, {
    articles: action.data.articles,
    sections: action.data.sections,
    loading: false,
    error: false,
  });
  return temp;
};

const fetchArticlesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ARTICLES_START:
      return fetchArticlesStart(state, action);
    case actionTypes.FETCH_ARTICLES_SUCCESS:
      return fetchArticlesSuccess(state, action);
    case actionTypes.FETCH_ARTICLES_FAIL:
      return fetchArticlesFail(state, action);

    default:
      return state;
  }
};

export default reducer;
