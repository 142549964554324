import React from "react";
import T from "./../../hoc/Language/translate.js";
import Link from "react-router-dom/Link";

export default function LearnMoreButton(props) {
  return props.buttonLink != null && props.buttonLink.length > 1 ? (
    <div className={`learnMoreWrapper`}>
      {props.buttonLink[0] == "/" ? (
        <Link to={props.buttonLink} className={"Link"}>
          <button className={"Button"}>{T("Learn More", props.lang)}</button>
        </Link>
      ) : (
        <a target="_blank" href={props.buttonLink} className={"Link"}>
          <button className={"Button"}>{T("Learn More", props.lang)}</button>
        </a>
      )}
    </div>
  ) : null;
}
