import React from "react";
import classes from "./Logo.module.scss";
import { NavLink } from "react-router-dom";

let LogoClass = null;

const switcher = (type) => {
  switch (type) {
    case "header":
      return classes.Header;
    case "footer":
      return classes.Footer;
    default:
      return null;
  }
};

export default function Logo(props) {
  LogoClass = switcher(props.logoType);
  return (
    <div onClick={props.closed} className={classes.Logo}>
      <NavLink to={`/${props.language}`}>
        <div className={`${classes.ImageDiv} ${LogoClass}`}>
          {props.logoType == "footer" ? (
            <picture>
              {/*Use AVIF iamge if the browser supports it*/}
              <source
                srcSet={
                  "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/logo-footer-ag-alt.avif"
                }
                type="image/avif"
              />
              {/*Use webp iamge if the browser supports it*/}
              <source
                srcSet={
                  "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/logo-footer-ag-alt.webp"
                }
                type="image/avif"
              />
              {/*Fall back if the browser does not support avig*/}
              <img
                className={classes.Image}
                src="https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/logo-footer-ag-alt.png"
                alt="Great Plains Ag Logo"
              />
            </picture>
          ) : (
            <picture>
              {/*Use AVIF iamge if the browser supports it*/}
              <source
                srcSet={
                  "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/ag-logo.avif"
                }
                type="image/avif"
              />
              {/*Use webp iamge if the browser supports it*/}
              <source
                srcSet={
                  "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/ag-logo.webp"
                }
                type="image/avif"
              />
              {/*Fall back if the browser does not support avig*/}
              <img
                className={classes.Image}
                src="https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/ag-logo.png"
                alt="Great Plains Ag Logo"
              />
            </picture>
          )}
          <span className={classes.Text}>Home</span>
        </div>
      </NavLink>
    </div>
  );
}
