import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchFinance = (lang) => {
  const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];
  let OriginLanguage = langArray.includes(lang) ? lang : "en";

  return (dispatch) => {
    dispatch(fetchFinanceStart(OriginLanguage));
    axios
      .get(`/articles/finance/${OriginLanguage}`)
      .then((response) => {
        dispatch(fetchFinanceSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchFinanceFail(err));
      });
  };
};

/***********************************************************
 // Fetch All Finance
 ***********************************************************/
export const fetchFinanceStart = () => {
  return {
    type: actionTypes.FETCH_FINANCE_START,
  };
};

export const fetchFinanceSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_FINANCE_SUCCESS,
  };
};

export const fetchFinanceFail = (error) => {
  return {
    type: actionTypes.FETCH_FINANCE_FAIL,
    error: error,
  };
};
