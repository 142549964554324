import { connect } from "react-redux";
import React from "react";
import classes from "./Toolbar.module.scss";
import Primary from "./Primary/Primary.js";
import Secondary from "./Secondary/Secondary.js";
import Tertiary from "./Tertiary/Tertiary.js";
import AdminBar from "../../Auth/AdminBar/AdminBar";

class Header extends React.PureComponent {
  render() {
    let toolbar = null;

    if (!this.props.loading) {
      toolbar = (
        <div className={`${classes.ToolbarWrapper} ToolbarWrapper `}>
          {this.props.auth.auth ? <AdminBar /> : null}
          <header className={`${classes.Toolbar} Toolbar`}>
            <Tertiary
            // language={this.props.language}
            ></Tertiary>
            <Primary
              drawerToggleClicked={this.props.drawerToggleClicked}
              closed={this.props.closed}
              open={this.props.open}
              language={this.props.language}
            ></Primary>
            <Secondary
            // language={this.props.language}
            ></Secondary>
          </header>
        </div>
      );
    }
    return toolbar;
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.origin.language,
    loading: state.nav.loading,
    auth: { ...state.auth },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onFetchHeader: () => dispatch(actions.fetchHeader())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
