import React from "react";
import { connect } from "react-redux";
import classes from "./NavigationItems.module.scss";
import NavigationItem from "./NavigationItem/NavigationItem";

class navigationItems extends React.PureComponent {
  render() {
    //will render a loading icon if the data isn't loaded in yet
    let navItems = <div>loading</div>;
    if (!this.props.loading) {
      let current = this.props.language
        ? this.props.nav[2].languages[this.props.language].links
        : this.props.nav[2].languages["en-gb"].links;
      // if (current == null || current == undefined) {
      //   current = this.props.nav[2].languages["en-gb"].links;
      // }

      navItems = current.map((item, index) => (
        <NavigationItem
          key={index}
          link={item.link}
          icon={item.link == "/search" ? null : item.icon}
          exact
        >
          {item.text}
        </NavigationItem>
      ));
    }
    return (
      <div className={`${classes.MiscNavRibbon} MiscNavRibbon`}>
        <ul className={`${classes.NavigationItems} NavigationItems`}>
          {navItems}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nav: state.nav.nav.navLinks,
    language: state.origin.language,
    loading: state.nav.loading,
  };
};

export default connect(mapStateToProps)(navigationItems);
