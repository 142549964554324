import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  manuals: null,
  manualsSequential: null,
  loading: true,
  searchString: "",
  searching: false,
};

const fetchManualsStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchManualsSuccess = (state, action) => {
  let manualsSequential = [];
  try {
    for (const category in action.data) {
      action.data[category].forEach((manual) => {
        manualsSequential.push(manual);
      });
    }
  } catch (e) {}
  return updateObject(state, {
    manualsSequential: manualsSequential,
    manuals: action.data,
    loading: false,
    error: false,
    Category: action.data.AllCats,
  });
};

const fetchManualClear = (state, action) => {
  return updateObject(state, {
    manuals: null,
    manualsSequential: null,
    loading: true,
    searchString: "",
    searching: false,
  });
};

// const fetchManualsSuccess = (state, action) => {
//   return updateObject(state, {
//     ...action.data,
//     loading: false,
//     error: false,
//   });
// };

const fetchManualsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MANUALS_START:
      return fetchManualsStart(state, action);
    case actionTypes.FETCH_MANUALS_SUCCESS:
      return fetchManualsSuccess(state, action);
      state, action;
    case actionTypes.FETCH_MANUALS_FAIL:
      return fetchManualsFail(state, action);
    case actionTypes.FETCH_MANUALS_CLEAR:
      return fetchManualClear(state, action);
    // case actionTypes.FILTER_CHANGE: return filterChange(state, action);
    // case actionTypes.SEARCH_STRING_UPDATE: return searchStringUpdate(state, action);
    default:
      return state;
  }
};

export default reducer;
