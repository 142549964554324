import React, { Component } from "react";
import { connect } from "react-redux";
import ErrorDisplay from "./../../components/ErrorDisplay/ErrorDisplay";
import NotFound from "./../../components/NotFound/NotFound";
import Spinner from "../../components/UI/Spinner/Spinner";
import { Redirect } from "react-router-dom";
import Parser from "html-react-parser";

/*
 ** ---------------------------------
 ** DISCLAIMER - this file and just piggybacks off of the origin call to redirect.
 ** ---------------------------------
 */

class Node extends Component {
  render() {
    let contentType;
    let nid;

    if (isNaN(+this.props.match.params.language)) {
      nid = this.props.match.params.nid;
    } else {
      nid = this.props.match.params.language;
    }
    let title = "";
    if (this.props.origin.nodeMap[nid]) {
      title = this.props.origin.nodeMap[nid].title;
    }
    let nodeMap = this.props.origin.nodeMap;
    let current = nodeMap[nid];

    if (current && current.content_type_machine_name || current.content_type ) {
      contentType = current.content_type_machine_name || current.content_type
        ? current.content_type
        : "404";
    }


    let errorScreen;

    if (nid == 391) {
      return <Redirect to={`/${this.props.origin.language}/391/news`} />;
    }


    switch (contentType) {
      case "Product Series or Model":
        return (
          <Redirect
            to={`/${this.props.origin.language}/products/${nid}/${Parser(title)
              .toLowerCase()
              .replaceAll(/[.,\\/#!$%^&*;:{}=\\-_\`~()]/g, "")
              .replaceAll(" ", "-")
              .replaceAll("'", "")
              .replaceAll('"', "")
              .trim()}${this.props.location.search}`}
          />
        );
        break;
      case "blind-redirects":
        const url = nodeMap[nid].destination_url.endsWith("/")
          ? nodeMap[nid].destination_url.slice(0, -1)
          : nodeMap[nid].destination_url;
        if (!this.props.origin.nodeMap[nid].destination_url.includes("http")) {
          return <Redirect to={`/${this.props.origin.language}${url}`} />;
        } else {
          window.location.replace(this.props.origin.nodeMap[nid].destination_url);
        }
        return (
          <div>
            <Spinner />
          </div>
        );
        break;

      case "Manual Listing Pages":
        return (
          <Redirect
            to={`/${this.props.origin.language}/manuals/${nid}/${Parser(title)
              .toLowerCase()
              .replaceAll(/[.,\\/#!$%^&*;:{}=\\-_\`~()]/g, "")
              .replaceAll(" ", "-")
              .replaceAll("'", "")
              .replaceAll('"', "")
              .trim()}`}
          />
        );
        break;
      case "Product Manual Sets":
        return (
          <Redirect
            to={`/${this.props.origin.language}/manual/${nid}/${Parser(title)
              .toLowerCase()
              .replaceAll(/[.,\\/#!$%^&*;:{}=\\-_\`~()]/g, "")
              .replaceAll(" ", "-")
              .replaceAll("'", "")
              .replaceAll('"', "")
              .trim()}`}
          />
        );
        break;
      case "Service, Product, and Operational Videos":
        return (
          <Redirect
            to={`/${this.props.origin.language}/video/${nid}/${Parser(title)
              .toLowerCase()
              .replaceAll(/[.,\\/#!$%^&*;:{}=\\-_\`~()]/g, "")
              .replaceAll(" ", "-")
              .replaceAll("'", "")
              .replaceAll('"', "")
              .trim()}`}
          />
        );
        break;
      case "News":
        return (
          <Redirect
            to={`/${this.props.origin.language}/news/${nid}/${Parser(title)
              .toLowerCase()
              .replaceAll(/[.,\\/#!$%^&*;:{}=\\-_\`~()]/g, "")
              .replaceAll(" ", "-")
              .replaceAll("'", "")
              .replaceAll('"', "")
              .trim()}`}
          />
        );
        break;
      case "Agronomy":
        return (
          <Redirect
            to={`/${this.props.origin.language}/agronomy/${nid}/${Parser(title)
              .toLowerCase()
              .replaceAll(/[.,\\/#!$%^&*;:{}=\\-_\`~()]/g, "")
              .replaceAll(" ", "-")
              .replaceAll("'", "")
              .replaceAll('"', "")
              .trim()}`}
          />
        );
        break;
      case "Alerts":
        return (
          <Redirect
            to={`/${this.props.origin.language}/article/${nid}/${Parser(title)
              .toLowerCase()
              .replaceAll(/[.,\\/#!$%^&*;:{}=\\-_\`~()]/g, "")
              .replaceAll(" ", "-")
              .replaceAll("'", "")
              .replaceAll('"', "")
              .trim()}`}
          />
        );
        break;

      case "CRM Forms":
        return (
          <Redirect
            to={`/${this.props.origin.language}/forms/${nid}/${Parser(title)
              .toLowerCase()
              .replaceAll(/[.,\\/#!$%^&*;:{}=\\-_\`~()]/g, "")
              .replaceAll(" ", "-")
              .replaceAll("'", "")
              .replaceAll('"', "")
              .trim()}`}
          />
        );
        break;
      case "Product Features":
      case "Feature":
        return (
          <Redirect
            to={`/${
              this.props.origin.language
            }/product-features/${nid}/${Parser(title)
              .toLowerCase()
              .replaceAll(/[.,\\/#!$%^&*;:{}=\\-_\`~()]/g, "")
              .replaceAll(" ", "-")
              .replaceAll("'", "")
              .replaceAll('"', "")
              .trim()}`}
          />
        );
        break;
      case "Product Testimonials":
        return (
          <Redirect
            to={`/${this.props.origin.language}/testimonials/${nid}/${Parser(
              title
            )
              .toLowerCase()
              .replaceAll(/[.,\\/#!$%^&*;:{}=\\-_\`~()]/g, "")
              .replaceAll(" ", "-")
              .replaceAll("'", "")
              .replaceAll('"', "")
              .trim()}`}
          />
        );
        break;
      case "Landing Pages":
        return (
          <Redirect
            to={`/${this.props.origin.language}/${current.landing_page_url}`}
          />
        );
        break;
      case "Redirects":
        if (
          this.props.origin.nodeMap[nid].redirect_url.includes(
            "greatplainsag.com"
          )
        ) {
          return (
            <Redirect
              to={
                this.props.origin.nodeMap[nid].redirect_url.split(
                  "greatplainsag.com"
                )[1]
              }
            />
          );
        } else {
          window.location.replace(this.props.origin.nodeMap[nid].redirect_url);
        }
        //todo make a component to display this prettier
        return (
          <div>
            <Spinner />
          </div>
        );
        break;
      case "Article":
        return (
          <Redirect
            to={`/${this.props.origin.language}/article/${nid}/${title
              .toLowerCase()
              .replaceAll(/[.,\\/#!$%^&*;:{}=\\-_\`~()]/g, "")
              .replaceAll(" ", "-")
              .replaceAll("'", "")
              .replaceAll('"', "")
              .trim()}`}
          />
        );
        break;
      default:
        return <NotFound />;
        break;
    }
    return <div>{errorScreen ? errorScreen : <ErrorDisplay />}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    origin: { ...state.origin },
  };
};

export default connect(mapStateToProps, null)(Node);
