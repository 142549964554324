import React from "react";
import { connect } from "react-redux";
import classes from "./NavigationItems.module.scss";
import LinkSwitch from "./../../../../LinkSwitch/LinkSwitch";
import LanguageFlipper from "../../../../../hoc/Language/LanguageFlipper";

class navigationItems extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    //will render a loading icon if the data isn't loaded in yet
    let navItems = <div>loading</div>;
    if (!this.props.loading) {
      let current = this.props.language
        ? this.props.nav[1].languages[this.props.language].links
        : this.props.nav[1].languages["en-gb"].links;
      // if (current == null || current == undefined) {
      //   current = this.props.nav[1].languages["en-gb"].links;
      // }
      navItems = current.map(
        (item, index) =>
          item.showInHeader && (
            <LinkSwitch key={item.text} link={item.link} text={item.text} />
          )
      );
    }
    return (
      <div>
        <nav className={`${classes.NavigationItems} NavigationItems`}>
          <div className={`${classes.NavigationItems} NavigationItems`}>
            {navItems}
          </div>
          {/*This removes it when we go to moblie so it dosent fire off 2 times*/}
          {!this.props.loading && this.state.width > 899 ? (
            <LanguageFlipper />
          ) : null}
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nav: state.nav.nav.navLinks,
    language: state.origin.language,
    loading: state.nav.loading,
  };
};
export default connect(mapStateToProps)(navigationItems);
