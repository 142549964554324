import React from "react";
import classes from "./AdminBar.module.scss";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import CacheDropDown from "./CacheDropDown";

class AdminBar extends React.PureComponent {
  // componentDidMount() {
  //   const scriptRecaptcha = document.createElement("script");
  //   scriptRecaptcha.id = "recap";
  //   scriptRecaptcha.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_ReCaptchaV3_DealerSearch}`;
  //   scriptRecaptcha.async = true;
  //   document.body.appendChild(scriptRecaptcha);
  // }
  //
  // componentWillUnmount() {
  //   var Recap = document.getElementById("recap");
  //   if (Recap || Recap != null) {
  //     Recap.remove();
  //   }
  // }
  render() {
    return (
      <nav className={classes.AdminBarWrapper}>
        <ul className={classes.ListWrapper}>
          <li>
            <CacheDropDown />
          </li>
          <li>
            <a
              href="https://cms.greatplainsag.com/en/admin/content-tracking"
              target="_blank"
            >
              Content Tracking
            </a>
          </li>
          <li onClick={() => this.props.onfetchAuthLogout()}>Logout &times;</li>
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: { ...state.auth },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onfetchAuthLogout: () => dispatch(actions.fetchAuthLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBar);
