import React, { Fragment } from "react";
import NavigationItems from "./NavigationItems/NavigationItems";
import classes from "./SideDrawer.module.scss";

// import NavigationItems from '../NavigationItems/NavigationItems';
// import Backdrop from '../../../UI/Backdrop/Backdrop';
// import Aux from '../../../hoc/Aux/Aux';

const sideDrawer = (props) => {
  let attachedClasses = [classes.SideDrawer, classes.Close];
  let attachedClassesSecondary = [classes.ExtraContent, classes.Close];
  let attachedToggle = [classes.ToggleButton, classes.Right];

  if (props.open) {
    attachedClasses = [classes.SideDrawer, classes.Open];
  }

  if (props.moreOpen) {
    attachedClassesSecondary = [classes.ExtraContent, classes.Open];
    attachedToggle = [classes.ToggleButton, classes.Down];
  }

  const tertiaryClasses = `${classes.List}  ${classes.Tertiary}`;

  return (
    <Fragment>
      {/* <Backdrop show={props.open} clicked={props.closed} /> */}
      <div className={`${attachedClasses.join(" ")} Wrapper`}>
        <div>
          <ul className={`${classes.List} List`} onClick={props.closed}>
            <NavigationItems type={"primary"} closed={props.closed} />
          </ul>

          <button
            className={`${attachedToggle.join(" ")} Toggle`}
            onClick={props.moreToggle}
          >
            {props.moreOpen ? "Less" : "More"}
          </button>
          <ul
            className={`${attachedClassesSecondary.join(" ")} List`}
            onClick={props.closed}
          >
            <NavigationItems type={"secondary"} />
          </ul>
          <ul className={tertiaryClasses}>
            <NavigationItems type={"tertiary"} closed={props.closed} />
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default sideDrawer;
