import classes from "../Footlight.module.scss";
import React from "react";
import { uuidv4 } from "../../../shared/utility";
import { Link } from "react-router-dom";
import T from "../../../hoc/Language/translate";

export default React.memo(function Company(props) {
  return (
    <>
      <div className={`${classes.FootlightChildWrapper} FootlightChildWrapper`}>
        <div className={`${classes.InnerWrapper} InnerWrapper`}>
          <Link to={`/${props.lang}/facilities`}>
            {" "}
            <h3 className={`${classes.Header} Header`}>
              {/*<span>GREAT PLAINS</span>{" "}*/}
              {T("GREAT PLAINS DIVISIONS AND FACILITIES", props.lang)}
            </h3>
            <h6 className={`${classes.Customer} Customer`}>
              {T(
                "Learn about our company, philosophy, and the people behind our products.",
                props.lang
              )}
            </h6>
          </Link>
          <div className={`${classes.Content} Content`}>
            <div className={`${classes.ImageWrapper} ImageWrapper`}>
              <Link to={`/${props.lang}/facilities`}>
                <picture>
                  {/*Use AVIF iamge if the browser supports it*/}
                  <source
                    srcSet={
                      "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/corporate_video_square_13kpx.avif"
                    }
                    type="image/avif"
                  />
                  {/*Use webp iamge if the browser supports it*/}
                  <source
                    srcSet={
                      "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/corporate_video_square_13kpx.webp"
                    }
                    type="image/avif"
                  />
                  {/*Fall back if the browser does not support avig*/}
                  <img
                    className={`${classes.Image} Image`}
                    src={
                      "https://cdn-assets.greatplainsmfg.com/ag_files/styles/scale_1300_wide/public/squares/corporate_video_square_13kpx.jpeg"
                    }
                    alt={"Customer Product Image"}
                    loading={"lazy"}
                  />
                </picture>
              </Link>
            </div>
          </div>
          <p className={`${classes.Company} Company`}>
            {T(
              "As a proud member of the Kubota family, Great Plains comprises five divisions dedicated to providing customers with high quality agricultural, turf management, dirt working, and material handling products.",
              props.lang
            )}
          </p>

          <div className={`${classes.Buttons} Buttons`}>
            <Link to={`/${props.lang}/facilities`}>
              <button className={`${classes.Button} Button`}>
                {T("More Details", props.lang)}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
});
