import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchProducts = (familyPath, lang) => {
  return (dispatch) => {
    const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];
    let OriginLanguage = langArray.includes(lang) ? lang : "en-gb";

    dispatch(fetchProductsStart());
    axios
      .get(`/products/${OriginLanguage}/`)
      .then((response) => {
        localStorage.setItem("products_loaded", true);
        if (familyPath == undefined || familyPath == "all") {
          familyPath = "all-products";
        }

        dispatch(fetchProductsSuccess(response.data, familyPath));
      })
      .catch((err) => {
        console.log(err, "IN ACTIONS");
      });
  };
};

export const fetchProduct = (nId, lang) => {
  const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];
  let OriginLanguage = langArray.includes(lang) ? lang : "en-gb";
  return (dispatch) => {
    //
    // let tokenDate = localStorage.getItem('products_loaded')
    //
    dispatch(fetchProductStart());
    axios
      .get(`/products/${OriginLanguage}/${nId}`)
      .then((response) => {
        // const expirationDate = new Date(new Date().getTime() + 3 * 60 * 60 * 1000);
        // localStorage.setItem('products_loaded', true);
        dispatch(fetchProductSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchProductsFail());
      });
  };
};

/***********************************************************
 // Filter Options
 ***********************************************************/
export const filterChange = (parent, child) => {
  return {
    parent: parent,
    child: child,
    type: actionTypes.FILTER_CHANGE,
  };
};

export const toggleShowChildren = (section) => {
  return {
    section: section,
    type: actionTypes.TOGGLE_SHOW_CHILDREN,
  };
};

export const searchStringUpdate = (string) => {
  return {
    type: actionTypes.SEARCH_STRING_UPDATE,
    string: string,
  };
};
export const resetFlags = () => {
  return {
    type: actionTypes.RESET_FLAGS,
  };
};

/***********************************************************
 // Fetch All Products
 ***********************************************************/
export const fetchProductsStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCTS_START,
  };
};

export const fetchProductsSkipped = () => {
  return {
    type: actionTypes.FETCH_PRODUCTS_SKIPPED,
  };
};

export const fetchProductsSuccess = (data, familyPath) => {
  return {
    data: data,
    familyPath: familyPath,
    type: actionTypes.FETCH_PRODUCTS_SUCCESS,
  };
};

export const fetchProductsFail = (error) => {
  return {
    type: actionTypes.FETCH_PRODUCTS_FAIL,
    error: error,
  };
};

/***********************************************************
 // Fetch All Products
 ***********************************************************/
export const fetchProductStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCT_START,
  };
};

export const fetchProductSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_PRODUCT_SUCCESS,
  };
};

export const fetchProductFail = (error) => {
  return {
    type: actionTypes.FETCH_PRODUCT_FAIL,
    error: error,
  };
};
