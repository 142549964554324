import classes from "../Footlight.module.scss";
import React from "react";
import T from "../../../hoc/Language/translate.js";
import { uuidv4 } from "../../../shared/utility";
import Parser from "html-react-parser";
import { Link } from "react-router-dom";

export default function Shows(props) {
    const today = new Date();
    const Style = {
    textDecoration: "line-through",
    color: "transparent",
    textShadow: "0 0 5px rgba(0,0,0,0.5)",
  };
  const SorryTitle = {
    margin: "1rem",
  }
    const Sorry = props.shows != undefined && props.shows.upcomingShows != undefined && props.shows.upcomingShows.upcomingShows != undefined ?
        <>  <h6 className={`${classes.Title} Title`} style={SorryTitle}>
            {T("Currently we have no upcoming shows at this time. Please check back soon.", props.lang)}
        </h6>
            {props.shows.upcomingShows.upcomingShows.slice(0, 3).map(function (data) {
                return (
                    <div className={`${classes.Shows} Shows`} key={uuidv4()}>
                        <h6 style={Style}>
                            {Parser(data.title)}
                        </h6>
                        <div className={`${classes.Location} Location`} style={Style}>
                            {Parser(data.field_location)}
                        </div>
                        <div className={`${classes.Dates} Dates`} style={Style}>
                            <span> {Parser(data.field_event_date)} </span>
                        </div>
                    </div>
                )
            })}
        </> : null;

    const content = props.shows.upcomingShows.upcomingShows.filter(function (data) {
        if (data.field_relevance_by_locale.includes(props.country)) {
            return data
        }
    }).filter(function (data) {
        const lastIndex = data.field_event_date.split(" ").length - 1;
        const dateExp = data.field_event_date.split(" ")[lastIndex].toString();
        const date2 = new Date(dateExp);
        if (today < date2) {
            return data
        }

    }).sort((a, b) => a.field_weight_value - b.field_weight_value).slice(0, 3).map(function (data) {
        return (
            <div className={`${classes.Shows} Shows`} key={uuidv4()}>
                <h6 className={`${classes.Title} Title`}>
                    {Parser(data.title)}

                </h6>
                <div className={`${classes.Location} Location`}>
                    {Parser(data.field_location)}
                </div>
                <div className={`${classes.Dates} Dates`}>
                    <span> {Parser(data.field_event_date)} </span>
                </div>
            </div>
        )
    });
    return (
    <>
      <div className={`${classes.InnerWrapper} InnerWrapper`}>
        <h3 className={`${classes.Header} Header`}>
          {T("Upcoming Shows", props.lang)}
        </h3>
        <div className={`${classes.Content} Content`}>
          {content.length > 0 ? content : Sorry}
        </div>
        {content.length > 0 ?  <div className={`${classes.Buttons} Buttons`}>
          <Link to={`/${props.lang}/node/22`}>
            <button className={`${classes.Button} Button`}>
              {T("More Shows", props.lang)}
            </button>
          </Link>
        </div>: null}
      </div>
    </>
  );
}
