import React from "react";
import Parser from "html-react-parser";
import classes from "./divisions.module.scss";
import { uuidv4 } from "../../../shared/utility";

export default React.memo(function DivisionsSingle(props) {
  return (
    <div className={classes.Wrapper} key={uuidv4()}>
      {/* <div className={classes.Image} style={style}> </div> */}

      <div className={classes.ContentWrapper} key={uuidv4()}>
        <div className={classes.Header}>
          <h3>{props.division.title}</h3>
          <div className={classes.SubDivision}>{props.division.division}</div>
        </div>

        <div className={classes.Content}>
          <div className={classes.Text}>{Parser(props.division.body)}</div>
          <button className={classes.Button}>
            <a href={props.division.link_target}>{props.division.title}</a>
          </button>
        </div>
      </div>
    </div>
  );
});
