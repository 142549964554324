import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  news: null,
  newsMap: null,
  loading: true,
};

const fetchNewsStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchNewsSuccess = (state, action) => {
  let newsMap = {};
  action.data.forEach((element) => {
    newsMap[element.nid] = element;
  });
  return updateObject(state, {
    news: action.data,
    newsMap: newsMap,
    loading: false,
    error: false,
  });
};

const fetchNewsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NEWS_START:
      return fetchNewsStart(state, action);
    case actionTypes.FETCH_NEWS_SUCCESS:
      return fetchNewsSuccess(state, action);
    case actionTypes.FETCH_NEWS_FAIL:
      return fetchNewsFail(state, action);

    default:
      return state;
  }
};

export default reducer;
