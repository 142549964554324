import translationsJson from "../../config/json/transaltions.json";

export default function T(Text, Lang) {
  if (Text == undefined || Text.length == 0 || Text == "" || Text == " ") {
    console.log(
      "%c Text Input Should Not Be Blank! Parameters are text to translate and language.",
      "background: #222; color: #bada55"
    );
  }
  if (Lang == undefined || Lang.length == 0 || Lang == "" || Lang == " ") {
    console.log(
      "%c Language input will return the same text input if blank or EN. Parameters are text to translate and language.",
      "background: #222; color: #ff73da"
    );
  }

  let file = translationsJson;
  let TextTransform = Text.toLowerCase().trim();
  // let TextTransform = Text;

  let arrayV2;
  if (Lang != undefined && Lang != "en" && Lang != "") {
    arrayV2 = file
      .filter(function (data, index) {
        if (data.source == TextTransform) {
          return true;
        } else false;
      })
      .map(function (data, index) {
        return data.translations[Lang];
      });
  } else {
    return Text;
  }

  if (arrayV2 != undefined && arrayV2.length && arrayV2 != "") {
    return arrayV2.join("");
  } else return Text;
}
