import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: true,
};

const fetchSerialStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchSerialSuccess = (state, action) => {
  return updateObject(state, {
    serial: action.data,
    loading: false,
    error: false,
  });
};

const fetchSerialFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const fetchReset = (state, action) => {
  return updateObject(state, {
    serial: action.data,
    loading: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SERIAL_START:
      return fetchSerialStart(state, action);
    case actionTypes.FETCH_SERIAL_SUCCESS:
      return fetchSerialSuccess(state, action);
    case actionTypes.FETCH_SERIAL_FAIL:
      return fetchSerialFail(state, action);
    case actionTypes.FETCH_SERIAL_RESET:
      return fetchReset(state, action);
    default:
      return state;
  }
};

export default reducer;
