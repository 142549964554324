import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchLocations = (lang) => {
  const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];
  let OriginLanguage = langArray.includes(lang) ? lang : "en-gb";
  return (dispatch) => {
    dispatch(fetchLocationsStart());
    axios
      .get(`/locations/${OriginLanguage}`)
      .then((response) => {

        dispatch(fetchLocationsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchLocationsFail(err));
      });
  };
};

/***********************************************************
 // Fetch All Divisions
 ***********************************************************/
export const fetchLocationsStart = () => {
  return {
    type: actionTypes.FETCH_LOCATIONS_START,
  };
};

export const fetchLocationsSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_LOCATIONS_SUCCESS,
  };
};

export const fetchLocationsFail = (error) => {
  return {
    type: actionTypes.FETCH_LOCATIONS_FAIL,
    error: error,
  };
};
