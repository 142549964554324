import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchDealerInt = (lang) => {
  const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];
  let OriginLanguage = langArray.includes(lang) ? lang : "en-gb";

  return (dispatch) => {
    dispatch(fetchDealerIntStart());
    axios
      .get(`/intl-dealers/${OriginLanguage}`)
      .then((response) => {
        dispatch(fetchDealerIntSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchDealerIntFail(err));
      });
  };
};

/***********************************************************
 // Fetch All Products
 ***********************************************************/
export const fetchDealerIntStart = () => {
  return {
    type: actionTypes.FETCH_DEALERINT_START,
  };
};

export const fetchDealerIntSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_DEALERINT_SUCCESS,
  };
};

export const fetchDealerIntFail = (error) => {
  return {
    type: actionTypes.FETCH_DEALERINT_FAIL,
    error: error,
  };
};
