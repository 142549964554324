import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchFacilties = (lang) => {
  const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];
  let OriginLanguage = langArray.includes(lang) ? lang : "en-gb";

  return (dispatch) => {
    dispatch(fetchFaciltiesStart());
    axios
      .get(`/facilities/${OriginLanguage}`)
      .then((response) => {
        dispatch(fetchFaciltiesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchFaciltiesFail(err));
      });
  };
};

/***********************************************************
 // Fetch All FieldAdjustment
 ***********************************************************/
export const fetchFaciltiesStart = () => {
  return {
    type: actionTypes.FETCH_FACILITIES_START,
  };
};

export const fetchFaciltiesSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_FACILITIES_SUCCESS,
  };
};

export const fetchFaciltiesFail = (error) => {
  return {
    type: actionTypes.FETCH_FACILITIES_FAIL,
    error: error,
  };
};
