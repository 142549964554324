import "core-js/stable";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import "./index.scss";
import App from "./App";
import navReducer from "./store/reducers/nav";
import newsReducer from "./store/reducers/news";
import originReducer from "./store/reducers/origin";
import homeReducer from "./store/reducers/home";
import productsReducer from "./store/reducers/products";
import manualsReducer from "./store/reducers/manuals";
import formsReducer from "./store/reducers/forms";
import featuresReducer from "./store/reducers/product-feature";
import upcomingShowsReducer from "./store/reducers/upcomingShows";
import articlesReducer from "./store/reducers/articles";
import bannersReducer from "./store/reducers/banners";
import literatureReducer from "./store/reducers/literature";
import fieldadjustmentReducer from "./store/reducers/fieldadjustment";
import searchReducer from "./store/reducers/search";
import serialReducer from "./store/reducers/serial";
import BrowserChecker from "./hoc/BrowserCheck/BrowserChecker";
import dealerIntReducer from "./store/reducers/dealerInt";
import divisionsReducer from "./store/reducers/divisions";
import facilitiesReducer from "./store/reducers/facilities";
import locationsReducer from "./store/reducers/locations";
import authReducer from "./store/reducers/auth";
import authAgronomy from "./store/reducers/agronomy";
import financeReducer from "./store/reducers/finance";
import partsmartReducer from "./store/reducers/partsmart"

console.warn = () => {};
const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;
// const composeEnhancers = compose;

const rootReducer = combineReducers({
  home: homeReducer,
  nav: navReducer,
  news: newsReducer,
  origin: originReducer,
  products: productsReducer,
  articles: articlesReducer,
  banners: bannersReducer,
  manuals: manualsReducer,
  upcomingShows: upcomingShowsReducer,
  literature: literatureReducer,
  features: featuresReducer,
  search: searchReducer,
  forms: formsReducer,
  fieldAdjustment: fieldadjustmentReducer,
  dealerInt: dealerIntReducer,
  serial: serialReducer,
  facilities: facilitiesReducer,
  divisions: divisionsReducer,
  locations: locationsReducer,
  auth: authReducer,
  agronomy: authAgronomy,
  finance: financeReducer,
  partsmart: partsmartReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <BrowserChecker debug={false} />
    <BrowserRouter>
      <Fragment>
        <App />
      </Fragment>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
// registerServiceWorker();
