import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchLiterature = (lang) => {
  const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];
  let OriginLanguage = langArray.includes(lang) ? lang : "en-gb";
  return (dispatch) => {
    dispatch(fetchLiteratureStart());
    axios
      .get(`/literature/${OriginLanguage}`)
      .then((response) => {
        dispatch(fetchLiteratureSuccess(response.data));
      })
      .catch((err) => {
        console.log("failed the literature fetch", err);
        dispatch(fetchLiteratureFail(err));
      });
  };
};

/***********************************************************
 // Fetch All Literature
 ***********************************************************/
export const fetchLiteratureStart = () => {
  return {
    type: actionTypes.FETCH_LITERATURE_START,
  };
};

export const fetchLiteratureSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_LITERATURE_SUCCESS,
  };
};

export const fetchLiteratureFail = (error) => {
  return {
    type: actionTypes.FETCH_LITERATURE_FAIL,
    error: error,
  };
};
