import React from "react";
import classes from "./Primary.module.scss";
import Logo from "../../../Logo/Logo";
import NavigationItems from "./NavigationItems/NavigationItems";
import DrawerToggle from "../../../SideDrawer/DrawerToggle/DrawerToggle";

const primary = (props) => (
  <div className={`${classes.Primary} Primary`}>
    <div className={`${classes.PrimaryNav} PrimaryNav`}>
      <Logo
        logoType={"header"}
        closed={props.closed}
        language={props.language}
      />
      <NavigationItems
        isAuthenticated={props.isAuth}
        language={props.language}
      />
      <DrawerToggle
        className={`${classes.Toggle} Toggle`}
        open={props.open}
        clicked={props.drawerToggleClicked}
      />
    </div>
  </div>
);

export default primary;
