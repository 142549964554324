import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import Spinner from "../../UI/Spinner/Spinner";
import DivisionsSingle from "./divisionsSingle";
import { uuidv4 } from "../../../shared/utility";
import Parser from "html-react-parser";
import loadable from "@loadable/component";
import T from "../../../hoc/Language/translate";

const Panel = loadable(() =>
  import("./../../../components/Banners/Panel/Panel")
);
const Message = loadable(() => import("./../../Messages/Messages"));

class Divisions extends React.PureComponent {
  async componentDidMount() {
    this.props.onFetchDivisions(this.props.origin.language);
  }

  render() {
    let content = <Spinner />;
    let Lang = this.props.origin.language;
    let divisions =
      this.props.divisions.divisions !== undefined
        ? this.props.divisions.divisions
        : null;
    let banner =
      this.props.divisions.banner !== undefined
        ? this.props.divisions.banner[0]
        : null;
    if (!this.props.origin.loading && !this.props.divisions.loading) {
      if (!this.props.origin.error && !this.props.divisions.error) {
        content = (
          <>
            <>
              {banner !== undefined ? (
                <Panel
                  noSpan={true}
                  products={true}
                  lang={this.props.origin.language}
                  data={{
                    field_caption_position: banner.field_caption_position,
                    // field_vertical_alignment: this.props.products.product[
                    //     this.props.match.params.nid
                    //     ].field_vertical_alignment,
                    field_horizontal_alignment:
                      banner.field_horizontal_alignment,
                    field_banner_image: banner.field_banner_image,
                    //
                    banner_title: banner.banner_title,
                    field_caption_summary: Parser(banner.field_caption),
                    // field_Models: `${Parser(
                    //     this.props.products.product[this.props.match.params.nid]
                    //         .field_model_numbers
                    // )}`,
                  }}
                  mobile={banner.field_banner_mobile_asset_url}
                  desktop={banner.field_banner_image_large_desktop}
                />
              ) : null}
            </>
            {divisions.length > 1 ? (
              <div id={`ContentMainWrapper`} className={`ContentMainWrapper`}>
                {divisions
                  .sort(function (a, b) {
                    if (a.title < b.title) {
                      return -1;
                    }
                    if (a.title > b.title) {
                      return 1;
                    }
                    return 0;
                  })
                  .map(function (data) {
                    return <DivisionsSingle division={data} key={uuidv4()} />;
                  })}
              </div>
            ) : (
              <Message
                header={T("Information", Lang)}
                message={T(
                  "We have no content for this language right now.",
                  Lang
                )}
                Type={"Info"}
              />
            )}
          </>
        );
      }
    }

    return (
      <div>
        <Helmet>
          <title>Divisions | Great Plains Ag </title>
          <meta name="description" content="Great Plains Facilities" />
        </Helmet>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    origin: { ...state.origin },
    divisions: state.divisions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchDivisions: (lang) => dispatch(actions.fetchDivisions(lang)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Divisions)
);
