import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  nav: {},
  loading: true,
};

const fetchNavStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchNavSuccess = (state, action) => {
  //
  return updateObject(state, {
    nav: action.nav,
    loading: false,
  });
};

const fetchNavFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NAV_START:
      return fetchNavStart(state, action);
    case actionTypes.FETCH_NAV_SUCCESS:
      return fetchNavSuccess(state, action);
    case actionTypes.FETCH_NAV_FAIL:
      return fetchNavFail(state, action);
    default:
      return state;
  }
};

export default reducer;
