import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";

export const fetchNews = (lang) => {
  const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];
  let OriginLanguage = langArray.includes(lang) ? lang : "en-gb";

  return (dispatch) => {
    dispatch(fetchNewsStart());
    axios
      .get(`/news/${OriginLanguage}`)
      .then((response) => {
        dispatch(fetchNewsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchNewsFail(err));
      });
  };
};

/***********************************************************
// Fetch All Products
***********************************************************/
export const fetchNewsStart = () => {
  return {
    type: actionTypes.FETCH_NEWS_START,
  };
};

export const fetchNewsSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_NEWS_SUCCESS,
  };
};

export const fetchNewsFail = (error) => {
  return {
    type: actionTypes.FETCH_NEWS_FAIL,
    error: error,
  };
};
