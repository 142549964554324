import React from "react";
import classes from "./AdminBar.module.scss";
import { getEnvironment } from "../../../shared/utility";

const SITE_KEY = process.env.REACT_APP_ReCaptchaV3_DealerSearch;
const CacheClearURL = "Y2T2cMJKTWZ535Gy";
const apiUrl = getEnvironment();
let timeOutId;

export default class CacheDropDown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dropdownMenu = React.createRef();
    this.state = {
      showMenu: false,
      products: false,
      landingPage: false,
      upcomingShows: false,
      manuals: false,
      banners: false,
      news: false,
      articles: false,
      squares: false,
      literature: false,
    };
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.clearTheCache = this.clearTheCache.bind(this);
    this.changeLabelBack = this.changeLabelBack.bind(this);
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   console.log(this.state.token);
  // }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  }

  closeMenu() {
    // if (this.dropdownMenu.current != event.target) {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
    // }
  }

  changeLabelBack(type) {
    this.setState({ [type]: false });
  }

  clearTheCache(type, event) {
    event.preventDefault();
    this.setState({ [type]: true });
    let JWT = sessionStorage.getItem("token");

    // this.setState({ clicked: true });
    if (JWT && JWT.length > 10) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(SITE_KEY, { action: "Clear_Cache" })
          .then((token) => {
            const data = new URLSearchParams();
            const formdata = {
              recaptcha: token,
              token: JWT,
            };
            Object.entries(formdata).map(function (a) {
              data.append(a[0], a[1]);
            });

            fetch(
              `${apiUrl}/${CacheClearURL}/${type}`,
              { method: "POST", body: data },
              { mode: "no-cors" }
            ).then((res) => {
              res.status == "200"
                ? alert(`Successfully Cleared Cache For: ${type}`)
                : alert(
                    "Could not clear cache at this time. Max limit per hour reached."
                  );
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  }

  render() {
    return (
      <div
        onClick={this.showMenu}
        ref={this.dropdownMenu}
        className={classes.DropDown}
      >
        {" "}
        Cache Clear List{" "}
        <img
          src={
            this.state.showMenu
              ? "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/bullets/bullet-arrow-south-orange-8x8px.png"
              : "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/bullets/bullet-arrow-east-orange-8px.png"
          }
          alt={"arrow"}
          className={classes.ICON}
        />
        {this.state.showMenu ? (
          <>
            <ul className={classes.DropDownUl}>
              <li
                className={this.state.products ? classes.Clicked : null}
                onClick={(event) => this.clearTheCache("products", event)}
              >
                Products
              </li>
              <li
                className={this.state.articles ? classes.Clicked : null}
                onClick={(event) => this.clearTheCache("articles", event)}
              >
                Articles
              </li>
              <li
                className={this.state.news ? classes.Clicked : null}
                onClick={(event) => this.clearTheCache("news", event)}
              >
                News
              </li>
              <li
                className={this.state.banners ? classes.Clicked : null}
                onClick={(event) => this.clearTheCache("banners", event)}
              >
                Banners
              </li>
              <li
                className={this.state.manuals ? classes.Clicked : null}
                onClick={(event) => this.clearTheCache("manuals", event)}
              >
                Manuals
              </li>

              <li
                className={this.state.upcomingShows ? classes.Clicked : null}
                onClick={(event) => this.clearTheCache("upcomingShows", event)}
              >
                Shows
              </li>
              <li
                className={this.state.landingPage ? classes.Clicked : null}
                onClick={(event) => this.clearTheCache("landingPage", event)}
              >
                Landing Pages
              </li>
              <li
                className={this.state.squares ? classes.Clicked : null}
                onClick={(event) => this.clearTheCache("squares", event)}
              >
                Squares
              </li>
              <li
                className={this.state.literature ? classes.Clicked : null}
                onClick={(event) => this.clearTheCache("literature", event)}
              >
                Literature
              </li>
            </ul>
          </>
        ) : null}
      </div>
    );
  }
}
