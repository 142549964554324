import * as actionTypes from "./actionTypes";
// import axios from 'axios'
import axios from "../../axios-api";

const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"];

export const fetchHome = (lang) => {
  let OriginLanguage = langArray.includes(lang) ? lang : "en-gb";
  return (dispatch) => {
    dispatch(fetchHomeStart());
    axios
      .get(`/landing-page/home/${OriginLanguage}`)
      .then((res) => {
        dispatch(fetchHomeSuccess(res));
      })
      .catch((err) => {
        dispatch(fetchHomeFail(err));
      });
  };
};

export const fetchHomeStart = () => {
  return {
    type: actionTypes.FETCH_HOME_START,
  };
};

export const fetchHomeSuccess = (home) => {
  return {
    type: actionTypes.FETCH_HOME_SUCCESS,
    home: home.data,
  };
};

export const fetchHomeFail = (error) => {
  return {
    type: actionTypes.FETCH_HOME_FAIL,
    error: error,
  };
};

// export const fetchHome = () => {
//   return dispatch => {
//     dispatch(fetchHomeStart());
//     axios.get('/pages/home' )
//       .then(res => {
//         dispatch(fetchHomeSuccess(res.data));
//       })
//       .catch(err => {
//         dispatch(fetchHomeFail(err));
//       });
//   };
// };
