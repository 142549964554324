import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  literature: null,
  loading: true,
  error: false,
};

const fetchLiteratureStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchLiteratureSuccess = (state, action) => {
  return updateObject(state, {
    literature: action.data,
    loading: false,
    error: false,
  });
};

const fetchLiteratureFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LITERATURE_START:
      return fetchLiteratureStart(state, action);
    case actionTypes.FETCH_LITERATURE_SUCCESS:
      return fetchLiteratureSuccess(state, action);
      state, action;
    case actionTypes.FETCH_LITERATURE_FAIL:
      return fetchLiteratureFail(state, action);
    default:
      return state;
  }
};

export default reducer;
