import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    loading: true,
    error: false
};

const fetchPartsmartStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
};

const fetchPartsmartSuccess = (state, action) => {
    return updateObject(state, {
        tokens: action.data,
        loading: false,
        error: false,
    });
};

const fetchPartsmartFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: true,
    });
};

const fetchPartsmartClear = (state, action) => {

    return updateObject(state, {
        tokens: {},
        loading: true,
        error: false,
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PARTSMART_START:
            return fetchPartsmartStart(state, action);
        case actionTypes.FETCH_PARTSMART_SUCCESS:
            return fetchPartsmartSuccess(state, action);
        case actionTypes.FETCH_PARTSMART_FAIL:
            return fetchPartsmartFail(state, action);
        case actionTypes.FETCH_PARTSMART_CLEAR:
            return fetchPartsmartClear(state, action);
        default:
            return state;
    }
};

export default reducer;
