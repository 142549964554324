import React from "react";
import {connect} from "react-redux";
import classes from "./Lang.module.scss";
import * as actions from "../../store/actions/index";
import redirectsJson from "../../config/json/redirects.json";
import {withRouter} from 'react-router'
import T from "./translate"

let labels = {
  "ru": "Russian",
  "en-gb": "International: English",
  "es": "Spanish",
  "en": "United States: English",
  "uk": "Ukrainian",
  "ro" : "Romanian",
}
let headers ={
  "ru": "Russian",
  "en-gb": "English: Intl" ,
  "es": "Spanish",
  "en": "English: U.S.",
  "uk": "Ukrainian",
  "ro" : "Romanian",
}


class LanguageFlipper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dropdownMenu = React.createRef();
    this.state = {
      language: "",
      showMenu: false,
      label: "Great Plains International",
    };

    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.UrlTrigger = this.UrlTrigger.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.redirects =  this.redirects.bind(this)
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  }
  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  }

componentDidUpdate(prevProps, prevState, snapshot) {
  this.redirects(redirectsJson)
}
  redirects(JSON) {
    let i = 0, len = JSON.length;
    while (i < len) {
      if (this.props.origin.language != "en" && JSON[i].multiple && window.location.pathname ==`/${this.props.origin.language}${JSON[i].url}`) {
        window.location.href = `${JSON[i].language[this.props.origin.language]}`
      }
      if(!JSON[i].multiple && window.location.pathname == `/${this.props.origin.language}${JSON[i].url}` ){
        window.location.href = `${JSON[i].language[this.props.origin.language]}`
      }

      i++
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("click", this.closeMenu);
  }


  async componentDidMount() {
    this.setState({ label: labels[this.props.origin.language] })
    this.dropdownMenu.current;
  //this.setState({ language: this.props.origin.language }); //set the state to what the language is at first
    const langNotSupported = ["da","zh-hans", "af", "ja", "fr", "fi", "nl", "eo", "he" , "hi", "is", "ku", "pl" ,"sv" ,"cy", "zu" , "za", "th","sw"]
    const langArray = ["en", "ru", "ro", "es", "uk", "en-gb"]
    const windowsLocation = window.location.pathname.split("/")[1]
    if( langArray.includes(windowsLocation)
    ) {
      window.location.pathname.split("/")[1] != this.props.origin.language ?  this.UrlTrigger(windowsLocation) : null
    }

    if(windowsLocation == "de" ){
      window.location.href = 'https://www.kverneland.de/Great-Plains';
    } else if(windowsLocation == "fr"){
      window.location.href = "https://fr.kverneland.com/Great-Plains"
    } else if(windowsLocation == "da"){
      window.location.href = 'https://www.kverneland.de/Great-Plains';
    } else if( langNotSupported.includes(windowsLocation)
    ) {
      window.location.href = "/en-gb"
    }


  }

  UrlTrigger(LANG) {
        this.setState({ language: `${LANG}`, label: `${headers[LANG]}` });
        this.props.onFetchSearch(LANG); // grab new search
        this.props.onfetchOrigin(LANG);
        this.props.onfetchOriginUpdate({ language: `${LANG}`}); // get a new origin
     }
  handleLanguageChange(Lang) {
    let replacementString;
    // document.cookie =  `lang=${Lang}; max-age=1800; path=/`
    this.setState({ language: Lang });
    this.props.onfetchOriginUpdate({ language: Lang }); //ORIGIN UPDATE will update the sites language preferences en => ru. Thats all it dose
    this.props.onLanguageSiteChange({ language: Lang }); //This will make a call to that languages nodeIndex we pick
    this.props.onFetchSearch(Lang);
    if(Lang == "ro"){
      replacementString = `/ro/`;
    }else replacementString = `/${Lang}/${window.location.pathname.split("/").splice(2).join("/")}`;
    this.props.history.replace(replacementString);
  }

  render() {
    return (
      <div
        className={this.props.moblie ? `${classes.DropDownMobile}` : `${classes.DropDown}`}
        // style={this.state.showMenu ? style : style2}
        onClick={this.showMenu}
        ref={this.dropdownMenu}
      >
        {T(this.state.label, this.props.origin.language).toUpperCase()}
        {/*{`${this.state.label.toUpperCase()}`}*/}

        <img src={ this.state.showMenu ? "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/bullets/bullet-arrow-south-orange-8x8px.png": "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/bullets/bullet-arrow-east-orange-8px.png"  } alt={"arrow"}  className={classes.ICON} />
        {this.state.showMenu ? (
            <ul className={classes.DropDownUl} >

{!this.props.origin.loading ?
<>
              <li onClick={() => this.handleLanguageChange("en-gb")}>
                {T("English: Intl", this.props.origin.language)}
                {/*English: Intl*/}
              </li>
              <li onClick={() => this.handleLanguageChange("en")}>
                {T("English: U.S.", this.props.origin.language)}
               {/*English: U.S.*/}
              </li>
              <li onClick={() => this.handleLanguageChange("ru")}>
                {T("Russian", this.props.origin.language)}
             {/*Russian*/}
              </li>
              <li onClick={() => this.handleLanguageChange("es")}>
                {T("Spanish", this.props.origin.language)}
             {/*Spanish*/}
              </li>
              <li onClick={() => this.handleLanguageChange("uk")}>
                {T("Ukrainian", this.props.origin.language)}
                {/*Ukrainian*/}
              </li>
              <li onClick={() => this.handleLanguageChange("ro")}>
                {T("Romanian", this.props.origin.language)}
                {/*Romanian*/}
              </li>
</>
: null}

            </ul>

        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    search: { ...state.search },
    origin: { ...state.origin },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onfetchOriginUpdate: (lang) => dispatch(actions.fetchOriginUpdate(lang)),
    onfetchOrigin: (lang) => dispatch(actions.fetchOrigin(lang)),
    onLanguageSiteChange: (lang) =>
      dispatch(actions.fetchLanguageSiteChange(lang)),
    onFetchSearch: (lang) => dispatch(actions.fetchSearch(lang)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguageFlipper))
