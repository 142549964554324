/***********************************************************
//NAV_START = 'FETCH_NAV_START';
***********************************************************/
export const FETCH_NAV_START = "FETCH_NAV_START";
export const FETCH_NAV_SUCCESS = "FETCH_NAV_SUCCESS";
export const FETCH_NAV_FAIL = "FETCH_NAV_FAIL";
export const NAV = "NAV";

/***********************************************************
//MISSIONSTATEMENT_START = 'FETCH_MISSIONSTATEMENT_START';
***********************************************************/
export const FETCH_MISSIONSTATEMENT_START = "FETCH_MISSIONSTATEMENT_START";
export const FETCH_MISSIONSTATEMENT_SUCCESS = "FETCH_MISSIONSTATEMENT_SUCCESS";
export const FETCH_MISSIONSTATEMENT_FAIL = "FETCH_MISSIONSTATEMENT_FAIL";
export const MISSIONSTATEMENT = "MISSIONSTATEMENT";

/***********************************************************
//DIVISIONS_START = 'FETCH_DIVISIONS_START';
***********************************************************/
export const FETCH_DIVISIONS_START = "FETCH_DIVISIONS_START";
export const FETCH_DIVISIONS_SUCCESS = "FETCH_DIVISIONS_SUCCESS";
export const FETCH_DIVISIONS_FAIL = "FETCH_DIVISIONS_FAIL";
export const DIVISIONS = "DIVISIONS";

/***********************************************************
 //Auth
 ***********************************************************/
export const FETCH_AUTH_START = "FETCH_AUTH_START";
export const FETCH_AUTH_SUCCESS = "FETCH_AUTH_SUCCESS";
export const FETCH_AUTH_FAIL = "FETCH_AUTH_FAIL";
export const FETCH_AUTH_LOGOUT = "FETCH_AUTH_LOGOUT";

/***********************************************************
//products
***********************************************************/
export const FETCH_HOME_START = "FETCH_HOME_START";
export const FETCH_HOME_SUCCESS = "FETCH_HOME_SUCCESS";
export const FETCH_HOME_FAIL = "FETCH_HOME_FAIL";

/***********************************************************
//products
***********************************************************/
export const FETCH_ORIGIN_START = "FETCH_ORIGIN_START";
export const FETCH_ORIGIN_SUCCESS = "FETCH_ORIGIN_SUCCESS";
export const FETCH_ORIGIN_FAIL = "FETCH_ORIGIN_FAIL";
export const FETCH_ORIGIN_UPDATE = "FETCH_ORIGIN_UPDATE";
export const FETCH_LANGUAGE_SITE_CHANGE = "FETCH_LANGUAGE_SITE_CHANGE";

// export const FETCH_STARTING_STATE = "FETCH_STARTING_STATE"
/***********************************************************
//news
***********************************************************/
export const FETCH_NEWS_START = "FETCH_NEWS_START";
export const FETCH_NEWS_SUCCESS = "FETCH_NEWS_SUCCESS";
export const FETCH_NEWS_FAIL = "FETCH_NEWS_FAIL";

/***********************************************************
//products
***********************************************************/
export const FETCH_PRODUCTS_START = "FETCH_PRODUCTS_START";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAIL = "FETCH_PRODUCTS_FAIL";
export const FETCH_PRODUCTS_SKIPPED = "FETCH_PRODUCTS_SKIPPED";
export const FILTER_CHANGE = "FILTER_CHANGE";
export const SEARCH_STRING_UPDATE = "SEARCH_STRING_UPDATE";
export const RESET_FLAGS = "RESET_FLAGS";
export const TOGGLE_SHOW_CHILDREN = "TOGGLE_SHOW_CHILDREN";

/***********************************************************
//product
***********************************************************/
export const FETCH_PRODUCT_START = "FETCH_PRODUCT_START";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAIL = "FETCH_PRODUCT_FAIL";

/***********************************************************
//manuals
***********************************************************/
export const FETCH_MANUALS_START = "FETCH_MANUALS_START";
export const FETCH_MANUALS_SUCCESS = "FETCH_MANUALS_SUCCESS";
export const FETCH_MANUALS_FAIL = "FETCH_MANUALS_FAIL";
export const FETCH_MANUALS_CLEAR = "FETCH_MANUALS_CLEAR";

/***********************************************************
 //Features
 ***********************************************************/
export const FETCH_FEATURES_START = "FETCH_FEATURES_START";
export const FETCH_FEATURES_SUCCESS = "FETCH_FEATURES_SUCCESS";
export const FETCH_FEATURES_FAIL = "FETCH_FEATURES_FAIL";

/***********************************************************
//upcoming shows
***********************************************************/
export const FETCH_UPCOMING_SHOWS_START = "FETCH_UPCOMING_SHOWS_START";
export const FETCH_UPCOMING_SHOWS_SUCCESS = "FETCH_UPCOMING_SHOWS_SUCCESS";
export const FETCH_UPCOMING_SHOWS_FAIL = "FETCH_UPCOMING_SHOWS_FAIL";

/***********************************************************
//articles
***********************************************************/
export const FETCH_ARTICLES_START = "FETCH_ARTICLES_START";
export const FETCH_ARTICLES_SUCCESS = "FETCH_ARTICLES_SUCCESS";
export const FETCH_ARTICLES_FAIL = "FETCH_ARTICLES_FAIL";

/***********************************************************
 //finance
 ***********************************************************/
export const FETCH_FINANCE_START = "FETCH_FINANCE_START";
export const FETCH_FINANCE_SUCCESS = "FETCH_FINANCE_SUCCESS";
export const FETCH_FINANCE_FAIL = "FETCH_FINANCE_FAIL";

/***********************************************************
 //AGRONOMY
 ***********************************************************/
export const FETCH_AGRONOMY_START = "FETCH_AGRONOMY_START";
export const FETCH_AGRONOMY_SUCCESS = "FETCH_AGRONOMY_SUCCESS";
export const FETCH_AGRONOMY_FAIL = "FETCH_AGRONOMY_FAIL";
export const FETCH_AGRONOMY_SINGLE_START = "FETCH_AGRONOMY_SINGLE_START";
export const FETCH_AGRONOMY_SINGLE_SUCCESS = "FETCH_AGRONOMY_SINGLE_SUCCESS";
export const FETCH_AGRONOMY_SINGLE_FAIL = "FETCH_AGRONOMY_SINGLE_FAIL";

/***********************************************************
//banners
***********************************************************/
export const FETCH_BANNERS_START = "FETCH_BANNERS_START";
export const FETCH_BANNERS_SUCCESS = "FETCH_BANNERS_SUCCESS";
export const FETCH_BANNERS_FAIL = "FETCH_BANNERS_FAIL";
/***********************************************************
 //search
 ***********************************************************/
export const FETCH_SEARCH_START = "FETCH_SEARCH_START";
export const FETCH_SEARCH_SUCCESS = "FETCH_SEARCH_SUCCESS";
export const FETCH_SEARCH_FAIL = "FETCH_SEARCH_FAIL";
/***********************************************************
 //Literature
 ***********************************************************/
export const FETCH_LITERATURE_START = "FETCH_LITERATURE_START";
export const FETCH_LITERATURE_SUCCESS = "FETCH_LITERATURE_SUCCESS";
export const FETCH_LITERATURE_FAIL = "FETCH_LITERATURE_FAIL";
/***********************************************************
 //Forms
 ***********************************************************/
export const FETCH_FORMS_START = "FETCH_FORMS_START";
export const FETCH_FORMS_SUCCESS = "FETCH_FORMS_SUCCESS";
export const FETCH_FORMS_FAIL = "FETCH_FORMS_FAIL";

/***********************************************************
 //FIELDADJUSTMENT
 ***********************************************************/
export const FETCH_FIELD_ADJUSTMENT_START = "FETCH_FIELD_ADJUSTMENT_START";
export const FETCH_FIELD_ADJUSTMENT_SUCCESS = "FETCH_FIELD_ADJUSTMENT_SUCCESS";
export const FETCH_FIELD_ADJUSTMENT_FAIL = "FETCH_FIELD_ADJUSTMENT_FAIL";

/***********************************************************
 //DEALER UPDATE
 ***********************************************************/
export const FETCH_DEALER_START = "FETCH_DEALER_START";
export const FETCH_DEALER_SUCCESS = "FETCH_DEALER_SUCCESS";
export const FETCH_DEALER_FAIL = "FETCH_DEALER_FAIL";

/***********************************************************
 //DEALER INT
 ***********************************************************/
export const FETCH_DEALERINT_START = "FETCH_DEALERINT_START";
export const FETCH_DEALERINT_SUCCESS = "FETCH_DEALERINT_SUCCESS";
export const FETCH_DEALERINT_FAIL = "FETCH_DEALERINT_FAIL";

/***********************************************************
 //Serial search
 ***********************************************************/
export const FETCH_SERIAL_START = "FETCH_SERIAL_START";
export const FETCH_SERIAL_SUCCESS = "FETCH_SERIAL_SUCCESS";
export const FETCH_SERIAL_FAIL = "FETCH_SERIAL_FAIL";
export const FETCH_SERIAL_RESET = "FETCH_SERIAL_RESET";

/***********************************************************
 //FACILITIES
 ***********************************************************/
export const FETCH_FACILITIES_START = "FETCH_FACILITIES_START";
export const FETCH_FACILITIES_SUCCESS = "FETCH_FACILITIES_SUCCESS";
export const FETCH_FACILITIES_FAIL = "FETCH_FACILITIES_FAIL";
/***********************************************************
 //LOCATIONS
 ***********************************************************/
export const FETCH_LOCATIONS_START = "FETCH_LOCATIONS_START";
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_LOCATIONS_FAIL = "FETCH_LOCATIONS_FAIL";

//part smart
export const FETCH_PARTSMART_START = "FETCH_PARTSMART_START";
export const FETCH_PARTSMART_SUCCESS = "FETCH_PARTSMART_SUCCESS";
export const FETCH_PARTSMART_FAIL = "FETCH_PARTSMART_FAIL";
export const FETCH_PARTSMART_CLEAR = "FETCH_PARTSMART_CLEAR";
